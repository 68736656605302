export const team = {
  subtitle: "Our team",
  title: "We pride ourselves on having <br>a <span>highly-skilled</span> team",
  teamMembers: [
    {
      id: 1,
      image: "kais.png",
      name: "Kais Gannouni",
      designation: "CEO",
      url: "http://kaisgannouni.com/",
    },
    {
      id: 2,
      image: "oussama.png",
      name: "Oussema Nefzi",
      designation: "Art Director",
      url: "https://www.linkedin.com/in/chongity/",
    },
    {
      id: 3,
      image: "maha.png",
      name: "Maha Yarmani",
      designation: "Digital Marketing Manager",
      url: "https://www.linkedin.com/in/maha-yarmani/",
    },

    {
      id: 4,
      image: "sha.png",
      name: "Shahrazed Sakhri",
      designation: " Web Developer",
      url: "https://www.linkedin.com/in/shahrazed-sakhri-149a3b152/",
    },
  ],
};
