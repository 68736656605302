import React from "react";
// import vid from "../assets/images/vids/header.mp4";
// import prom1 from "../assets/images/bg/promotion1.jpg";
// import prom2 from "../assets/images/bg/promotion2.jpg";
// import Compare from "react-compare-component";
// import ReactHtmlParser from "react-html-parser";
// import { Link } from "react-router-dom";
// import { motion } from "framer-motion";
// import { Behavior } from "react-compare-component/lib/controller/CompareController";

const home_video = ({ data }) => {
  return (
    <div class="infotechno-video-bg">
      {/* <div className="infotechno-video-bg_play"></div> */}
      {/* <div className="embed-container"> */}
        <video
          id="about-video"
          style={{ width: "100%", display: "block" }}
          // src="http://s3.amazonaws.com/wp_media-prod/app/video/c5-about-video-full-width.mp4"
          // src={vid}
          // src={"https://drive.google.com/file/d/1N8ymUir069KHMrhojzmFTxVzbolI0zSC/view"}
          src={"https://drive.google.com/uc?export=download&id=1N8ymUir069KHMrhojzmFTxVzbolI0zSC"}
          // poster="http://s3.amazonaws.com/wp_media-prod/app/video/c5-about-video.jpg"
          controls=""
          autoplay="true"
          loop="true"
          muted="true"
        ></video>
        {/* <iframe src="https://drive.google.com/file/d/1N8ymUir069KHMrhojzmFTxVzbolI0zSC/preview" width="640" height="480"   controls=""
          autoplay="true"
          loop="true"
          muted="true"></iframe> */}
        {/* <iframe
          id="brandwiser_intro"
          width="100%"
          height="80%"
          src="https://www.youtube-nocookie.com/embed/838s8SzrWyY?controls=0&rel=0&modestbranding=1&autoplay=1&mute=1&loop=1&color=white&showinfo=0&enablejsapi=1&iv_load_policy=3&playlist=838s8SzrWyY"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe> */}
        {/* <Compare
        left={
          <div style={{ width: "100%", position: "relative" }}>
            <img src={prom2} style={{ width: "100%", objectFit: "contain" }} />
            <p
              style={{
                textAlign: "justify",
                margin: "50px",
                width: "40%",
                height: "100%",
                position: "absolute",
                top: 0,
                right: 87,
                color: "#202020",
                fontSize: "18px",
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {data.prom2.desc}
              <br />
              <Link
                to="/contact"
                style={{ color: "#e74c3c", marginRight: " 10px" }}
              >
                {ReactHtmlParser(data.prom2.call)}
              </Link>
            </p>
          </div>
        }
        right={
          <div>
            <img src={prom1} style={{ width: "100%", objectFit: "fill" }} />
            <p
              style={{
                textAlign: "justify",
                margin: "50px",
                width: "40%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 87,
                color: "#202020",
                fontSize: "18px",
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {data.prom1.desc}
              <br />
              <Link
                to="/contact"
                style={{ color: "#e74c3c", marginRight: " 10px" }}
              >
                {ReactHtmlParser(data.prom1.call)}
              </Link>
            </p>
          </div>
        }
        //  slider={ <div style={{display:"flex",minWidth: '100px',zIndex:"99"}}> <div style={{backgroundColor: 'black', width: '3px', height: '100%'}}/><div style={{backgroundColor: 'black', width: '3px', height: '100%'}}/></div>}
        slider={
          <div
            style={{
              backgroundColor: "#ffa931",
              width: "10px",
              height: "100%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              position: "relative",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                backgroundColor: "#ffa931",
                color: "#e74c3c",
                width: "70px",
                height: "70px",
                cursor: "pointer",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "40px",
              }}
            >
              {"< >"}
            </div>
          </div>
        }
        label="Compare Images"
        //  behavior={Behavior.Drag}
      /> */}
      {/* </div> */}
    </div>
  );
};

export default home_video;
