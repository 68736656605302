import React from 'react'
import FooterWrapper from "./footer"
const Footer = () => {
    return (
        <div>
            <FooterWrapper/>
        </div>
    )
}

export default Footer
