import React from "react";
import { Col, Row, Collapse } from "antd";
import { service_call } from "../../data/service_call";
import { market_me } from "../../data/market-me.js";
import ServiceCall from "../../components/service_call";
import ReactHtmlParser from "react-html-parser";
import ServicesDetails from "../../components/services_details"
import CtaContact from "../../components/home_contact";
const { Panel } = Collapse;
const Marketme = ({market}) => {
  console.log(market)
  return (
    <div className="market-me">
      <div className="market-me_header"></div>
      <div className="market-me_content">
        <div className="market-me_content_title">
          {ReactHtmlParser(market.about.title)}
        </div>
        <div className="market-me_content_desc">
          <p>{ReactHtmlParser(market.about.desc)}</p>
        </div>
       
      <ServicesDetails serviceData={market}  />
      </div>
      {/* <ServiceCall serviceName={service_call.call.marketme} /> */}
      <CtaContact data={market.contact}/>
    </div>
  );
};

export default Marketme;
