import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { openPopupWidget } from "react-calendly";
import { Progress, Col, Row } from "antd";
const Assessment_finish = ({ assessment, result, sections, isFrensh }) => {

  return (
    <div className="assessment-finish">
      <div className="result">
        {ReactHtmlParser(assessment.finish.title1)}
        <span>
          {result < 10
            ? result.toPrecision(1)
            : result < 100
            ? result.toPrecision(2)
            : result.toPrecision(3)}
          %{" "}
        </span>{" "}
        {ReactHtmlParser(assessment.finish.title2)}
      </div>
      <Row className="section-result-container">
        {sections.map((e) => (
          <Col xs={24} sm={12} lg={6} className="section-result" key={e.index}>
            <Progress
              type="circle"
              percent={e.sectionResult}
              width={100}
              strokeColor={
                e.sectionResult > 30 && e.sectionResult < 80 && "#f9c322"
              }
              status={
                e.sectionResult <= 30
                  ? "exception"
                  : e.sectionResult >= 80
                  ? "success"
                  : "active"
              }
              format={(percent) => percent.toFixed() + "%"}
            />
            <h5>{isFrensh ? e.titleFr : e.title}</h5>
          </Col>
        ))}
      </Row>
      <div className="brand">
        <p>{ReactHtmlParser(assessment.brandwiser.text)}</p>
        <Link
          onClick={() =>
            isFrensh
              ? 
              // openPopupWidget({
              //     url:
              //       "https://calendly.com/brandwiseragency/une-seance-decouverte",
              //     prefill: {
              //       name: "Nom et prénom",
              //       email: "exemple@exemple.com",
              //       customAnswers: {
              //         a1: "",
              //       },
              //     },
              //     pageSettings: {
              //       backgroundColor: "202020",
              //       hideEventTypeDetails: false,
              //       hideLandingPageDetails: false,
              //       primaryColor: "e74c3c",
              //       textColor: "ffffff",
              //     },
              //     utm: {},
              //   })*
              openPopupWidget({
                url:
                  "https://calendly.com/sheherazad-08/istichara",
                prefill: {
                  name: "Nom et prénom",
                  email: "exemple@exemple.com",
                  customAnswers: {
                    a1: "",
                  },
                },
                pageSettings: {
                  backgroundColor: "ffffff",
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: "202F84",
                  textColor: "2F281E",
                },
                utm: {},
              })
              : openPopupWidget({
                  url:
                    "https://calendly.com/brandwiseragency/discovery-session",
                  prefill: {
                    name: "Full Name",
                    email: "example@example.com",
                    customAnswers: {
                      a1: "",
                    },
                  },
                  pageSettings: {
                    backgroundColor: "202020",
                    hideEventTypeDetails: false,
                    hideLandingPageDetails: false,
                    primaryColor: "e74c3c",
                    textColor: "ffffff",
                  },
                  utm: {},
                })
          }
        >
          {ReactHtmlParser(assessment.brandwiser.button)}
        </Link>
   
      </div>
    </div>
  );
};

export default Assessment_finish;
