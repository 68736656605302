import React from "react";
import { Card,Carousel } from "antd";
const contentStyle = {
  // height: '160px',
  // color: '#fff',
  // lineHeight: '160px',
  // textAlign: 'center',
  // background: '#364d79',
};
const HomeTestimonials = ({ data }) => {
  return (
    <div className="test-card">
      <h6 class="test-card_subtitle">{data.subtitle}</h6>
      <div className="test-card_wrapper">
          {data.clients.map((e) => (
            <Card style={contentStyle}>
              <p>{e.text}</p>
              <Card.Meta title={e.name} description={e.post} />
            </Card>
          ))}
      </div>
    </div>
  );
};
export default HomeTestimonials;
