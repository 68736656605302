import React from 'react'
import { Col, Row, Collapse } from "antd";
import ReactHtmlParser from "react-html-parser";
const { Panel } = Collapse;
const Services_details = ({serviceData}) => {
    return (
        <div className="services-details">
        <div className="services-details_title">
          {ReactHtmlParser(serviceData.services.title)}
        </div>
        <div className="services-details_list">
          <Row>
            {serviceData.services.services.map((s) => (
              <Col xs={24} sm={12}>
                <div className="services-details_list_item">
                  <Collapse>
                    <Panel header={ReactHtmlParser(s.title)} key="1">
                      <ul>
                        {s.desc.map((e) => (
                          <li>{e}</li>
                        ))}
                      </ul>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    )
}

export default Services_details
