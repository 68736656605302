import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import {
  Steps,
  Button,
  message,
  Slider
} from "antd";
const { Step } = Steps;

const marks = {
  0: "0",
  10: "1",
  20: "2",
  30: "3",
  40: "4",
  50: "5",
  60: "6",
  70: "7",
  80: "8",
  90: "9",
  100: "10",
};

const Assessment = ({
  assessment,
  calcResult,
  reset,
  sectionResult,
  isFrensh,
}) => {
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    reset();
  }, []);

  const [sections, setSections] = useState([]);

  useEffect(() => {
    const sections = isFrensh
      ? [
        {
          title: "USP",
          order: 0,
          isOpen: current === 0,
          quests: [
            {
              index: 1,
              desc:
                "Je connais clairement les compétences que j'ai (les atouts de l'entreprise) et je peux les lister, tout de suite, une par une",
              result: 0,
            },
            {
              index: 2,
              desc:
                "Combinées et mises ensemble, mes compétences (mes atouts) me rendent unique",
              result: 0,
            },
            {
              index: 3,
              desc:
                "Je connais clairement les besoins de mes clients et les problèmes qu'ils veulent résoudre en ayant recours à mes compétences",
              result: 0,
            },
            {
              index: 4,
              desc:
                "Je suis sûr que les gens me paieront pour mes services (mes produits)",
              result: 0,
            },
            {
              index: 5,
              desc:
                "J'ai les preuves que les gens me paieront pour mes services (mes produits)",
              result: 0,
            },
          ],
        },
        {
          title: "Client Avatar",
          order: 1,
          isOpen: current === 1,
          quests: [
            {
              index: 6,
              desc:
                "Je connais les caractéristiques démographiques (âge, pays, revenus, profession) de mes clients et je peux les citer maintenant",
              result: 0,
            },
            {
              index: 7,
              desc:
                "Je connais les caractéristiques psychologiques (traits de personnalité) de mes clients et je peux les citer maintenant",
              result: 0,
            },
            {
              index: 8,
              desc:
                "Je connais le besoin exact de mes clients et je peux le citer maintenant",
              result: 0,
            },
            {
              index: 9,
              desc:
                "J'ai une estimation précise du nombre de la population que je cible",
              result: 0,
            },
          ],
        },
        {
          title: "Bruit",
          order: 2,
          isOpen: current === 2,
          quests: [
            {
              index: 10,
              desc:
                "Je suis clair sur la date du lancement de mon projet et je l'ai très bien choisie",
              result: 0,
            },
            {
              index: 11,
              desc:
                "Les messages de marketing que j'utiliserai dans la compagne de lancement sont provocants et séduisants et je peux les citer maintenant",
              result: 0,
            },
            {
              index: 12,
              desc:
                "Je connais très bien les récipients (vidéos, dessins animés, posters…) que j'utiliserai pour communiquer mes message de marketing et je peux les citer maintenant ",
              result: 0,
            },
            {
              index: 13,
              desc:
                "Je connais très bien les canaux (site Web, Facebook, Instagram…) que j'utiliserai pour communiquer mes message de marketing et je peux les citer maintenant ",
              result: 0,
            },
            {
              index: 14,
              desc:
                "Je connais très bien quek type de récepient à utiliser avec quel type de canal pour communiquer mes message de marketing et je peux les citer maintenant",
              result: 0,
            },
            {
              index: 15,
              desc:
                "Je connais très bien la fréquence que j'utiliserai pour communiquer mes message de marketing et je peux la citer maintenant",
              result: 0,
            },
            {
              index: 16,
              desc:
                "Je suis clair sur les offres spéciales que j'utiliserai pour attirer des clients et je peux les citer maintenant   ",
              result: 0,
            },
            {
              index: 17,
              desc:
                "Je suis clair sur le nombre d'engagement (clicks) que j'obtiendrai lors de la campagne de lancement",
              result: 0,
            },
          ],
        },
        {
          title: "Confiance",
          order: 3,
          isOpen: current === 3,
          quests: [
            {
              index: 18,
              desc:
                "L'entreprise que je lance a une histoire intéressante et je peux la mentionner maintenant",
              result: 0,
            },
            {
              index: 19,
              desc: "L'histoire de notre entreprise est vraiment capitivante",
              result: 0,
            },
            {
              index: 20,
              desc:
                "L'histoire de notre entreprise est déjà écrite en moins de 1000 mots",
              result: 0,
            },
            {
              index: 21,
              desc: "J'ai déjà des clients",
              result: 0,
            },
            {
              index: 22,
              desc: "J'ai déjà des témoignages de la part des clients",
              result: 0,
            },
            {
              index: 23,
              desc:
                "Mon site Web et mes réseaux sociaux sont conçus de manière professionnelle",
              result: 0,
            },
          ],
        },
        {
          title: "Interaction",
          order: 4,
          isOpen: current === 4,
          quests: [
            {
              index: 24,
              desc:
                "J'ai de contenu téléchargeable et 100% gratuitement sur mon site Web et sur mes réseaux sociaux",
              result: 0,
            },
            {
              index: 25,
              desc: "J'offre des services 100% gratuits",
              result: 0,
            },
            {
              index: 26,
              desc:
                "Chacun des récepients (videos, posters...) que j'utiliserai dans la compagne de lancement a un 'Call to action' (appel à l'action)",
              result: 0,
            },
            {
              index: 27,
              desc:
                "Les appel à l'action que j'utlise invite les clients à demander plus d'informations, à télécharger du matériel gratuit ou à s'inscrire à l'un des services gratuits",
              result: 0,
            },
            {
              index: 28,
              desc: "Je sais combien d'e-mails je vais collecter",
              result: 0,
            },
            {
              index: 29,
              desc: "Je sais combien de personnes je vais interagir avec",
              result: 0,
            },
            {
              index: 30,
              desc: "Je sais combien de personnes j'appellerai",
              result: 0,
            },
          ],
        },
        {
          title: "Funnel",
          order: 5,
          isOpen: current === 5,
          quests: [
            {
              index: 31,
              desc:
                "J'offre une période d'essai gratuite sur certains de mes services",
              result: 0,
            },
            {
              index: 32,
              desc:
                "J'offre la possbilité de remboursement en cas d'insatisfaction sur certains de mes services",
              result: 0,
            },
            {
              index: 33,
              desc: "J'offre quelque services à petits prix",
              result: 0,
            },
            {
              index: 34,
              desc: "J'offre des remises sur certains de mes services",
              result: 0,
            },
            {
              index: 35,
              desc:
                "Je suis clair sur les ventes à réaliser après le lancement de la compagne marketing",
              result: 0,
            },
          ],
        },
        {
          title: "Situation financière",
          order: 6,
          isOpen: current === 6,
          quests: [
            {
              index: 36,
              desc:
                "Je connais très le bien le budget que le lancement de la compagne de marketing nécessite",
              result: 0,
            },
            {
              index: 37,
              desc: "J'ai le budget qu'il faut",
              result: 0,
            },
          ],
        },
        {
          title: "Temps",
          order: 7,
          isOpen: current === 7,
          quests: [
            {
              index: 38,
              desc:
                "J'ai une idée claire sur le temps de travail qu'il faut faire pour bien mener la compagne de marketing",
              result: 0,
            },
            {
              index: 39,
              desc: "j'ai le temps qu'il faut",
              result: 0,
            },
          ],
        },
      ]
      : [
          {
            title: "USP",
            order: 0,
            isOpen: current === 0,
            quests: [
              {
                index: 1,
                desc:
                  "I am clear about the skills I have (assets the business have) and I can list them one by one right away?",
                result: 0,
              },
              {
                index: 2,
                desc: "When put together my skills (assets) make me unique",
                result: 0,
              },
              {
                index: 3,
                desc:
                  "I am clear about the clients’ needs and problems to solve using my skills (assets)",
                result: 0,
              },
              {
                index: 4,
                desc:
                  "I am sure that people will pay me for solving that problem for them?",
                result: 0,
              },
              {
                index: 5,
                desc: "I have the proofs that people will pay me for that",
                result: 0,
              },
            ],
          },
          {
            title: "Clients Avatar",
            order: 1,
            isOpen: current === 1,
            quests: [
              {
                index: 6,
                desc:
                  "I know the demographic characteristics (age, country, revenue, occupation) of my clients and I can name them now",
                result: 0,
              },
              {
                index: 7,
                desc:
                  "I know the psychological characteristics (personality traits) of my clients and I can name them now",
                result: 0,
              },
              {
                index: 8,
                desc:
                  "I know the exact problem of my client and I can state it now",
                result: 0,
              },
              {
                index: 9,
                desc:
                  "I have an estimation of the number of the population I am targeting",
                result: 0,
              },
            ],
          },
          {
            title: "Blast",
            order: 2,
            isOpen: current === 2,
            quests: [
              {
                index: 10,
                desc:
                  "I am clear about the right moment to launch the project and choose it on purpose",
                result: 0,
              },
              {
                index: 11,
                desc:
                  "The marketing messages I will be using are provoking and enticing and I can mention them now",
                result: 0,
              },
              {
                index: 12,
                desc:
                  "I am clear about the recipients (Videos, cartoons…) I will use to communicate that message and I can mention them now ",
                result: 0,
              },
              {
                index: 13,
                desc:
                  "I am clear about the channels (Website, Facebook, Instagram…) I will use to communicate that message and I can mention them now ",
                result: 0,
              },
              {
                index: 14,
                desc:
                  "I am clear about what kind of reception and channel I am going to use with each of marketing message and I can mention that now ",
                result: 0,
              },
              {
                index: 15,
                desc:
                  "I am clear about the frequency I will use to communicate that message and I can mention it now ",
                result: 0,
              },
              {
                index: 16,
                desc:
                  "I am clear about the special offers I will be using and I can mention them now  ",
                result: 0,
              },
              {
                index: 17,
                desc:
                  "I am clear about the number of engagements I will get from the launch campaign",
                result: 0,
              },
            ],
          },
          {
            title: "Trust",
            order: 3,
            isOpen: current === 3,
            quests: [
              {
                index: 18,
                desc: "The business I am launching has a story",
                result: 0,
              },
              {
                index: 19,
                desc: "The story of the this business is appealing",
                result: 0,
              },
              {
                index: 20,
                desc:
                  "The story of the this business is already written down in less that 1000 words",
                result: 0,
              },
              {
                index: 21,
                desc: "I already got clients",
                result: 0,
              },
              {
                index: 22,
                desc: "I have testimonials",
                result: 0,
              },
              {
                index: 23,
                desc: "My website and social media are professionally designed",
                result: 0,
              },
            ],
          },
          {
            title: "Engagement",
            order: 4,
            isOpen: current === 4,
            quests: [
              {
                index: 24,
                desc:
                  "I have 100% free downloadable material on my website and social media",
                result: 0,
              },
              {
                index: 25,
                desc: "I offer 100% free services",
                result: 0,
              },
              {
                index: 26,
                desc:
                  "Each message of the launch marketing campaign has a call to action",
                result: 0,
              },
              {
                index: 27,
                desc:
                  "The call to action leads clients to ask for more information, download free material or register in one of the free services",
                result: 0,
              },
              {
                index: 28,
                desc: "I know how many emails I will collect",
                result: 0,
              },
              {
                index: 29,
                desc: "I know how many people I will exchange with",
                result: 0,
              },
              {
                index: 30,
                desc: "I know how many people I will call",
                result: 0,
              },
            ],
          },
          {
            title: "Funnel",
            order: 5,
            isOpen: current === 5,
            quests: [
              {
                index: 31,
                desc: "I offer free trial period services",
                result: 0,
              },
              {
                index: 32,
                desc: "I offer refundable services",
                result: 0,
              },
              {
                index: 33,
                desc: "I offer small prices services",
                result: 0,
              },
              {
                index: 34,
                desc: "I offer up-sells",
                result: 0,
              },
              {
                index: 35,
                desc:
                  "I have a clear number on how many will register in these services",
                result: 0,
              },
            ],
          },
          {
            title: "Financial",
            order: 6,
            isOpen: current === 6,
            quests: [
              {
                index: 36,
                desc:
                  "I have a clear idea on the budget I need to launch the business",
                result: 0,
              },
              {
                index: 37,
                desc: "I have the budget",
                result: 0,
              },
            ],
          },
          {
            title: "Time",
            order: 7,
            isOpen: current === 7,
            quests: [
              {
                index: 38,
                desc:
                  "I have a clear idea on the working time I need to launch the business",
                result: 0,
              },
              {
                index: 39,
                desc: "I can do it",
                result: 0,
              },
            ],
          },
        ];

    setSections(sections);
  }, [current, isFrensh]);

  const onChange = (event, i) => {
    setSections(
      sections.map((e) =>
        e.order === current
          ? {
              ...e,
              quests: e.quests.map((el) =>
                el.index === i ? { ...el, result: event } : { ...el }
              ),
            }
          : { ...e }
      )
    );
  };
  const stepContent = (quest) => {
    return (
      <ol className="steps-content">
        {quest
          .map((el, i) => (
            <li key={el.index}>
              <p>{el.desc}</p>
              <Slider
                tooltipVisible={false}
                step={10}
                marks={marks}
                onChange={(event) => onChange(event, el.index)}
                defaultValue={el.result}
              />
            </li>
          ))}
      </ol>
    );
  };

  const toggleStep = (s) => {
    setSections(
      sections.map((e, i) =>
        i === s ? { ...e, isOpen: !e.isOpen } : { ...e, isOpen: false }
      )
    );
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const next = (q) => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    const c = current + 1;
    setCurrent(c);
    toggleStep(c);
    sectionResult(q.quests, q.order);
  };

  const prev = (q) => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    const c = current - 1;
    setCurrent(c);
    toggleStep(c);
    sectionResult(q.quests, q.order);
  };

  return (
    <div className="assessment-mobile">
      <div className="assessment-mobile_title">
        <h2>{ReactHtmlParser(assessment.assessment.title)}</h2>

        <p>{ReactHtmlParser(assessment.assessment.subtitle)}</p>
      </div>
      <div className="assessment-mobile_content">
        <Steps
          direction="vertical"
          current={current}
          onChange={(e) => {
            setCurrent(e);
            toggleStep(e + 1);
          }}
        >
          {sections.map((item) => (
            <Step
              className={item.isOpen ? "show-step" : "hide-step"}
              key={item.title}
              title={
                <div onClick={() => toggleStep(current)}> {item.title}</div>
              }
              description={
                <div>
                  {stepContent(item.quests)}
                  <div className="steps-action">
                    {current > 0 && (
                      <Button
                        size="large"
                        style={{ margin: "0 8px" }}
                        onClick={() => prev(item)}
                        className="prev-btn"
                      >
                        Previous
                      </Button>
                    )}
                    {current < sections.length-1 && (
                      <Button
                        size="large"
                        type="primary"
                        onClick={() => next(item)}
                        className="next-btn"
                      >
                        Next
                      </Button>
                    )}
                    {current === sections.length-1 && (
                      <Link to="/assessment-finish">
                        <Button
                          size="large"
                          type="primary"
                          onClick={() => {
                            sectionResult(item.quests, item.order);
                            calcResult();
                            message.success("Processing complete!");
                          }}
                          className="next-btn"
                        >
                          Done
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
              }
            />
          ))}
        </Steps>
      </div>
    </div>
  );
};

export default Assessment;
