export const market_me = {
   about: {
    title: "Vous avez du mal à <span> brander votre entreprise </span>? <br /> Les événements que vous organisez passent <span> inaperçus </span>?",
    desc:
      "MarketMe est exactement ce que vous cherchez. C'est plus qu'un espace de Marketing. C'est l'endroit idéal pour donner libre cours à votre imagination et fignoler un message de marketing percutant et attractif. Nous mettons à votre disposition notre expertise en consulting pour vous aider à mettre en valeur votre Unique Selling Propostion et attirer plus de clientèle.",
  },
  contact:{
    subtitle: "Vous avez une idée?",
    title: " <span>nous sommes là pour la réaliser.</span>",
    desc: " Demandez un devis gratuitement !",
    call: "Tendez la main maintenant!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Devis en ligne!",
  },
  services: {
    title: "MarketMe propose des services <span> personnalisés </span>",
    // services: [
    //   {
    //     title: '<i class = "fal fa-bullhorn"> </i> <span> Brand voice</span>',
    //     desc: ["Naming", "slogan", "Elevator Pitch", "Copywriting"],
    //   },
    //   {
    //     title:
    //       '<i class = "fal fa-pencil-ruler"> </i> <span>Branding strategy</span>',
    //     desc: [
    //       "Déclaration de mission de marque",
    //       "Étude de marché",
    //       "Analyse (SWOT / PESTLE)",
    //       "Positionnement de la marque",
    //       "Proposition de valeur",
    //       "Architecture de marque",
    //       "Avatar du client",
    //       "Recommandations",
    //     ],
    //   },
    //   {
    //     title:
    //       '<i class = "fal fa-calendar-alt"> </i> <span> Calendrier des réseaux sociaux personnalisé </span>',
    //     desc: ["Idées de publications sur les réseaux sociaux", "Calendrier pour organiser vos publications sur les réseaux sociaux"],
    //   },
    //   {
    //     title:
    //       '<i class = "fal fa-analytics"> </i> <span> Analyse des données </span>',
    //     desc: ["Analyse des performances des médias sociaux", "Analyse des performances du site Web", "Audit de santé du site Web"],
    //   },
    //   {
    //     title:
    //       '<i class = "fal fa-sticky-note"> </i> <span> Campagnes de branding </span>',
    //     desc: [
    //       "Lancement de marque",
    //       "Lancement d'un nouveau produit",
    //       "Notoriété de la marque",
        
    //     ],
    //   },
    //   {
    //     title:
    //       '<i class = "fal fa-comments"> </i> <span> Gestion de la communauté </span>',
    //     desc: ["Calendrier personnalisé", "Planification des publications", "Creator studio", "Gestion des groupes"],
    //   },
    // ],
    services: [
      {
        title: '  <i class="fal fa-bullhorn"></i> <span>Brand voice</span>',
        desc: ["Naming", "tagline/slogan", "Elevator Pitch", "Copywriting "],
      },
      {
        title:
          '  <i class="fal fa-pencil-ruler"></i> <span>Branding strategy</span>',
        desc: [
          "Brand mission statement",
          "Market Research",
          "Analysis (SWOT/PESTLE)",
          "Brand Positioning",
          "Value Proposition",
          "Brand Architecture",
          "Customer avatar",
          "Recommendations",
        ],
      },
      {
        title:
          '  <i class="fal fa-calendar-alt"></i> <span>Customized social media calendar</span>',
        desc: ["Social media post ideas","Calendar to organize your social media posts"],
      },
      {
        title:
          '  <i class="fal fa-analytics"></i> <span>Data analysis</span>',
        desc: ["Social media performance analysis","Website performance analysis","Website Health Audit"],
      },
      {
        title:
          '  <i class="fal fa-sticky-note"></i> <span>Branding campaigns</span>',
        desc: [
          "Brand Launch",
          "New Product Launch",
          "Brand Awareness ",
        
        ],
      },
      {
        title:
          ' <i class="fal fa-comments"></i> <span>Community management</span>',
        desc: ["Customized calendar","Posts Scheduling","Creator studio","Groups management"],
      },
    ],
  },
};