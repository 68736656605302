import React, { useState, useEffect } from "react";
import { Checkbox, Collapse, Row, Col, Modal } from "antd";
import emailjs from "emailjs-com";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateService } from "../../redux/contact-call/actions";
const { Panel } = Collapse;
const Service_call = ({ serviceBill }) => {
  const [mailForm, setMailForm] = useState({
    name: "",
    email: "",
    phone: "",
    industry: "",
    brand: "",
    budget: "",
    message: "",
    services: "",
  });
  const [services, setServices] = useState([]);
  useEffect(() => {
    setMailForm({ ...mailForm, services: services.join(" / ") });
  }, [services]);
  const addService = (e) => {
    e.target.checked &&
      services.filter((el) => el === e.target.value).length === 0 &&
      setServices(services.concat(e.target.value));
  };
  const sendEmail = (e) => {
    e.preventDefault();
    Object.values(mailForm).filter((e) => e !== "").length === 0
      ? Modal.error({
          title: "OOPS!... Form is empty!",
          content: (
            <div className="modal-error">
              <div>
                <img
                  src={require("../../assets/images/Gifs/Get_in_touch.gif")}
                  alt="animated"
                />
              </div>
              <p> Please make sure to fill all the fields</p>
            </div>
          ),
          onOk() {},
        })
      : emailjs
          .send(
            "brandwiser_mail",
            "services_template",
            mailForm,
            "user_LO2qAhi66N5K4CemNcmhv"
          )
          .then(
            (result) => {
              Modal.success({
                title: "Message Sent...",
                content: (
                  <div className="modal-error">
                    <div>
                      <img
                        src={require("../../assets/images/Gifs/emails.gif")}
                        alt="animated"
                      />
                    </div>
                    <p>THANK YOU! </p>
                    <p>We will contact you ASAP</p>
                  </div>
                ),
                onOk() {},
              });
            },
            (error) => {
              Modal.error({
                title: "Sorry!...Email Cannot Send",
                content: (
                  <div className="modal-error">
                    <div>
                      <img
                        src={require("../../assets/images/Gifs/oopss.gif")}
                        alt="animated"
                      />
                    </div>
                    <p>Please try again...</p>
                  </div>
                ),
                onOk() {},
              });
            }
          );
    setMailForm({
      name: "",
      email: "",
      phone: "",
      industry: "",
      brand: "",
      budget: "",
      message: "",
      services: "",
    });
  };

  return (
    <div className="service-bill">
      <div className="service-bill_title">
        {ReactHtmlParser(serviceBill.title)}
      </div>
      <div className="service-bill_desc">
        {ReactHtmlParser(serviceBill.subtitle)}
      </div>
      <div className="service-bill_content">
        <div className="service-bill_content_choices">
          <Row className="service-bill_content_choices_collapse">
            <Col
              xs={24}
              md={12}
              lg={5}
              span={1}
              className="service-bill_content_choices_collapse_service"
            >
              <Link
                to="/animate"
                className="service-bill_content_choices_collapse_service_title"
              >
                {serviceBill.animate.title}
              </Link>
              <div className="service-bill_content_choices_collapse_service_list">
                <Collapse accordion>
                  {serviceBill.animate.list.map((e, i) => (
                    <Panel header={e.title} key={i}>
                      {e.desc.map((d) => (
                        <Checkbox onChange={addService} value={d}>
                          {d}
                        </Checkbox>
                      ))}
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
            <div className="service-bill_content_choices_collapse_seperator"></div>
            <Col
              xs={24}
              md={12}
              lg={5}
              span={1}
              className="service-bill_content_choices_collapse_service"
            >
              <Link
                to="/designme"
                className="service-bill_content_choices_collapse_service_title"
              >
                {serviceBill.design.title}
              </Link>
              <div className="service-bill_content_choices_collapse_service_list">
                <Collapse accordion>
                  {serviceBill.design.list.map((e, i) => (
                    <Panel header={e.title} key={i}>
                      {e.desc.map((d) => (
                        <Checkbox onChange={addService} value={d}>
                          {d}
                        </Checkbox>
                      ))}
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
            <div className="service-bill_content_choices_collapse_seperator"></div>
            <Col
              xs={24}
              md={12}
              lg={5}
              span={1}
              className="service-bill_content_choices_collapse_service"
            >
              <Link
                to="/buildme"
                className="service-bill_content_choices_collapse_service_title"
              >
                {serviceBill.build.title}
              </Link>
              <div className="service-bill_content_choices_collapse_service_list">
                <Collapse accordion>
                  {serviceBill.build.list.map((e, i) => (
                    <Panel header={e.title} key={i}>
                      {e.desc.map((d) => (
                        <Checkbox onChange={addService} value={d}>
                          {d}
                        </Checkbox>
                      ))}
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
            <div className="service-bill_content_choices_collapse_seperator"></div>
            <Col
              xs={24}
              md={12}
              lg={5}
              span={1}
              className="service-bill_content_choices_collapse_service"
            >
              <Link
                to="/marketme"
                className="service-bill_content_choices_collapse_service_title"
              >
                {serviceBill.market.title}
              </Link>
              <div className="service-bill_content_choices_collapse_service_list">
                <Collapse accordion>
                  {serviceBill.market.list.map((e, i) => (
                    <Panel header={e.title} key={i}>
                      {e.desc.map((d) => (
                        <Checkbox onChange={addService} value={d}>
                          {d}
                        </Checkbox>
                      ))}
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
          </Row>
        </div>
        <form onSubmit={sendEmail}>
          <div className="service-bill_content_form">
            <div className="service-bill_content_form_person">
              <div className="service-bill_content_subtitle">
                {ReactHtmlParser(serviceBill.person)}
              </div>
              <input type="hidden" name="contact_number" />
              <label>{serviceBill.form.name}</label>
              <input
                type="text"
                name="name"
                value={mailForm.name}
                onChange={(e) => {
                  setMailForm({ ...mailForm, name: e.target.value });
                }}
              />
              <label>{serviceBill.form.email}</label>
              <input
                type="email"
                name="email"
                value={mailForm.email}
                onChange={(e) => {
                  setMailForm({ ...mailForm, email: e.target.value });
                }}
              />
              <label>{serviceBill.form.phone}</label>
              <input
                type="text"
                name="phone"
                value={mailForm.phone}
                onChange={(e) => {
                  setMailForm({ ...mailForm, phone: e.target.value });
                }}
              />
              <label>{serviceBill.form.industry}</label>
              <input
                type="text"
                name="industry"
                value={mailForm.industry}
                onChange={(e) => {
                  setMailForm({ ...mailForm, industry: e.target.value });
                }}
              />
            </div>
            <div className="service-bill_content_choices_collapse_seperator"></div>
            <div className="service-bill_content_form_project">
              <div className="service-bill_content_subtitle">
                {ReactHtmlParser(serviceBill.business)}
              </div>

              <label>{serviceBill.form.brand}</label>
              <input
                type="text"
                name="brand"
                value={mailForm.brand}
                onChange={(e) => {
                  setMailForm({ ...mailForm, brand: e.target.value });
                }}
              />
              <label>{serviceBill.form.budget}</label>
              <input
                type="text"
                name="budget"
                value={mailForm.budget}
                onChange={(e) => {
                  setMailForm({ ...mailForm, budget: e.target.value });
                }}
              />
              <label>{serviceBill.form.message}</label>
              <textarea
                name="message"
                rows="5"
                value={mailForm.message}
                onChange={(e) => {
                  setMailForm({ ...mailForm, message: e.target.value });
                }}
              />
            </div>
          </div>
          <button type="submit" className="service-bill_content_submit">
            <i class="far fa-comment-alt-dots"></i>
            <div>{ReactHtmlParser(serviceBill.btn)}</div>
          </button>
        </form>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateService: (x) => dispatch(updateService(x)),
  };
};
export default connect(null, mapDispatchToProps)(Service_call);
