
export const service_bill = {
  title: "Guarantee your success and<span> Take Action</span>",
  subtitle: "** Customize your choice by ticking the services based on your brand needs, then tell us about yourself and your brand",
  desc: "",
  btn:"Get a quote",
  person:"Let us know you: ",
  business:"Tell us about your project:",
  form:{
    name:"Name",
    email: "E-mail",
    phone: "Phone",
    industry: "Industry",
    brand: "Brand Name",
    budget: "Budget",
    message: "Message",
  },
  animate: {
    title: "Animate Me",
    list: [
      {
        title: "Videography",
        desc: ["Video shooting", "Video editing", "VFX: Visual effects"],
      },
      {
        title: "Photography",
        desc: ["Photo shoot", "Product Shoot", "LandScapes", "Post-production"],
      },
      {
        title: "2D Animations",
        desc: [
          "Informational video animation",
          "Educational video animation",
          "Promotional video animation",
          "Logo animation",
          "WhiteBoard Videos",
          "Character creation & animation",
        ],
      },
      {
        title: "Audio production ",
        desc: ["Jingles", "Sound effects", "Voice recording"],
      },
    ],
  },
  build: {
    title: "Build Me",
    list: [
      {
        title: "Websites",
        desc: [
          "Basic showcase ",
          "Custom showcase",
          "Landing Page",
          "E-commerce",
        ],
      },
      {
        title: "SEO",
        desc: [
          "Links building",
          "Website optimization",
          "Sitemap optimization",
          "Search Engine Tools Setup",
          "Traffic & Ranking",
        ],
      },
      {
        title: "Applications",
        desc: ["Web App", "Mobile App", "Desktop App"],
      },
      {
        title: "Email ",
        desc: ["Email automation", "Email setup", "Email signature"],
      },
      {
        title: "DevOps",
        desc: ["Server hosting", "Domain Name", "Deployment"],
      },
      {
        title: "Support & maintenance",
        desc: ["HelpDesk Support", "Server Management", "Dedicated Tech Team"],
      },
    ],
  },
  design: {
    title: "Design Me",
    list: [
      {
        title: "Visual Brand Identity",
        desc: [
          "Logo",
          "Typography",
          "Color palette",
          "Imagery: visual aspects",
        ],
      },
      {
        title: "UX/UI",
        desc: [
          "Web design",
          "Mobile app design",
          "Information Architecture Planning",
          "Wireframe",
          "Mockups",
        ],
      },
      {
        title: "Stationery items",
        desc: [
          "business card",
          "Letterhead",
          "Calendars",
          "Pens",
          "Presentation folder",
        ],
      },
      {
        title: "Social media assets",
        desc: ["Posters", "Covers"],
      },
      {
        title: "Banners, Brochures",
        desc: [
          "Products and events brochures",
          "Events brochures",
          "ads banners",
          "Website banners",
          "Roll ups",
          "Suspended & Hanging Banners",
        ],
      },
      {
        title: "Iconography",
        desc: ["Website icons", "Presentation icons", "Social media icons"],
      },
      {
        title: "Business Templates",
        desc: ["Booklets", "Presentations", "Reports", "Emails"],
      },
      {
        title: "Product Packaging",
        desc: ["Bags", "Bags", "Bottles"],
      },
    ],
  },
  market: {
    title: "Market Me",
    list: [
      {
        title: "Brand voice",
        desc: ["Naming", "tagline/slogan", "Elevator Pitch", "Copywriting "],
      },
      {
        title: "Branding strategy",
        desc: [
          "Brand mission statement",
          "Market Research",
          "Analysis (SWOT/PESTLE)",
          "Brand Positioning",
          "Value Proposition",
          "Brand Architecture",
          "Customer avatar",
          "Recommendations",
        ],
      },
      {
        title: "Customized social media calendar",
        desc: [
          "Social media post ideas",
          "Calendar to organize your social media posts",
        ],
      },
      {
        title: "Data analysis",
        desc: [
          "Social media performance analysis",
          "Website performance analysis",
          "Website Health Audit",
        ],
      },
      {
        title: "Branding campaigns",
        desc: ["Brand Launch", "New Product Launch", "Brand Awareness "],
      },
      {
        title: "Community management",
        desc: [
          "Customized calendar",
          "Posts Scheduling",
          "Creator studio",
          "Groups management",
        ],
      },
    ],
  },

  brandwiser: {
    title: "B.R.A.N.D.W.I.S.E.R",
    list: [
      "Customizable",
      "A Strategic Approach",
      "Practical",
      "Flexible",
      "Interactive",
      "Affordable",
    ],
  },
};
