import React from "react";
import { Col, Row } from "antd";
import { service_call } from "../../data/service_call";
import { design_me } from "../../data/design-me.js";
import ServiceCall from "../../components/service_call";
import ServicesDetails from "../../components/services_details"
import ReactHtmlParser from "react-html-parser";
import CtaContact from "../../components/home_contact";
const Designme = ({design}) => {
  return (
    <div className="design-me">
      <div className="design-me_header"></div>
      <div className="design-me_content">
        <div className="design-me_content_title">
          {ReactHtmlParser(design.about.title)}
        </div>
        <div className="design-me_content_desc">
          <p>{ReactHtmlParser(design.about.desc)}</p>
        </div>
    
        <ServicesDetails serviceData={design}  />
      </div>
      {/* <ServiceCall serviceName={service_call.call.designme} /> */}
      <CtaContact data={design.contact}/>
    </div>
  );
};

export default Designme;
