import React from 'react'
import { Helmet } from "react-helmet"
import TeamTwo from "../../components/home_team";
import HomeAbout from "../../components/home_about";
import HomeService from "../../components/home_service";
import HomeVideo from "../../components/home_video";
import CtaContact from "../../components/home_contact";
import HomeTestimonials from "../../components/home_testimonials"
// import OffCanvasMobileMenu from "../components/OffCanvasMobileMenu";

const Home = (home) => {
    return (
        <div >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Brandwiser || Home</title>
                <link rel="canonical" href="http://brandwiseragency.com" />
                <description>We run all kinds of branding that vow your success</description>
            </Helmet>
            <HomeVideo data={home.home.header}/>
            <HomeAbout data={home.home.about}/>
            <HomeService data={home.home.service}/>
            <TeamTwo data={home.home.team}/>
            <HomeTestimonials data={home.home.testemonials}/>
            <CtaContact data={home.home.contact}/>
            {/* <OffCanvasMobileMenu />  */}
        </div>
    )
}

export default Home
