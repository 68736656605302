export const tenfree = {
  title:
    "<span>GET IN TOUCH!</span> <br/> LET’S WORK TOGETHER TO CREATE GAME CHANGING EXPERIENCES  THAT WILL TAKE YOUR BRAND TO A WHOLE <span>NEW LEVEL</span> .",
  phone: "+216 51 500 097",
  email: "contact@brandwiseragency.com",
  facebook: "https://fb.me/Brandwiseragency",
  linkedin: "https://www.linkedin.com/company/brandwiser-agency/",
  youtube:
    "https://www.youtube.com/channel/UC8WjyYE35l5PwNvxTAHE1-g?view_as=subscriber",
  instagram: "https://www.instagram.com/brandwiseragency/",
  btn: "Submit",
};
