import * as CONSTANT from "./actionTypes";

const initState = {
  assessmentTab: [],
  result: 0,
  sections: [
    { index: 0, title: "USP",titleFr:"USP", sectionResult: 0 },
    { index: 1, title: "Clients Avatar",titleFr:"Clients Avatar", sectionResult: 0 },
    { index: 2, title: "Blast",titleFr:"Bruit", sectionResult: 0 },
    { index: 3, title: "Trust",titleFr:"Confiance", sectionResult: 0 },
    { index: 4, title: "Engagement",titleFr:"Interaction", sectionResult: 0 },
    { index: 5, title: "Funnel",titleFr:"Funnel", sectionResult: 0 },
    { index: 6, title: "Financial",titleFr:"Situation financière", sectionResult: 0 },
    { index: 7, title: "Time",titleFr:"Temps", sectionResult: 0 },
  ],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case CONSTANT.UPDATE_CHOICE:
      return {
        ...state,
        quests: state.quests.map((e) =>
          e.index === action.questIndex
            ? {
                ...e,
                result: action.questValue,
              }
            : e
        ),
      };
    case CONSTANT.CALC_RESULT:
      return {
        ...state,
        result:
          state.sections.map((e) => e.sectionResult).reduce((x, y) => x + y) /
          state.sections.length,
      };
    case CONSTANT.SECTION_RESULT:
      return {
        ...state,
        sections: state.sections.map((e) =>
          e.index === action.index
            ? {
                ...e,
                sectionResult:
                action.quest
                    .map((elt) => elt.result)
                    .reduce((x, y) => x + y) /
                    action.quest.length,
              }
            : e
        ),
      };
    case CONSTANT.RESET:
      return { ...state, result: 0 };
    default:
      return state;
  }
};
export default reducer;
