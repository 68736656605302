import React from "react";
import Head from "./header";
import SubHeader from "./subHeader";
import Header_mobile from "./header_mobile";
import { connect } from "react-redux";
import { setFrensh } from "../../redux/frensh/actions";
import { useWindowSize } from "@react-hook/window-size";

const Header = (props) => {
  const windowWidth = useWindowSize()[0];
  return (
    <div>
      {windowWidth >= 1200 ? (
        <>
          <Head {...props} />
          <SubHeader {...props}/>
        </>
      ) : (
        <Header_mobile {...props} />
      )}
    </div>
  );
};

const mstp = (state) => ({ isFrensh: state.isFrenshReducer.isFrensh });

const mdtp = (dispatch) => ({
  setFrensh: (x) => dispatch(setFrensh(x)),
});

export default connect(mstp, mdtp)(Header);
