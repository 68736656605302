import React from 'react'
import HomeWrapper from './home'
const Home = (props) => {
    return (
        <div>
            <HomeWrapper {...props}/>
        </div>
    )
}

export default Home
