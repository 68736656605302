export const about = {
  subtitle: "Nous faisons toute sorte de branding qui CONTRIBUE  à votre succès",
  title:
    "L'avenir de <span> branding </span> est ici et les <span> possibilités </span> sont ILLIMITÉES! ",
  desc:
    // "Nous utilisons notre méthode <span> B.R.A.N.D.W.I.S.E.R ™ </span> pour aider les entreprises à créer un MODÈLE D'AFFAIRES créatif qui réaliser des PRODUITS ET SERVICES UNIQUES, à se positionner de manière stratégique, à SE MARQUER, à accéder à de nouveaux marchés prometteurs locaux et internationaux, à AUGMENTER LEURS VENTES et à SOUTENIR LEUR CROISSANCE, même en TEMPS DE CRISE (ou de concurrence). ",
    // "Grâce à notre méthode <span> B.R.A.N.D.W.I.S.E.R ™ </span>, nous aidons les entreprises à:",
    "Grâce à notre stratégie B.R.A.N.D.W.I.S.E.R ™ , nous vous aidons à:",
  link: "en savoir plus sur BRANDWISER →",
  tasks: [
    "Évaluer les <span> forces et faiblesses de votre Brand </span>",
    "Concevoir un <span> Business Model créatif </span>",
    "Affiner votre <span> Unique Selling Proposition </span>",
    "Cibler <span> votre niche </span>",
    "Définir votre <span> votre Client Avatar </span>",
    "Produire des <span> vidéos</span>, créer des <span> dessins animés </span> et concevoir des <span> visuels </span>",
    "Communiquer un <span> message de marketing </span> percutant",
    "Accéder aux <span> marchés internationaux </span>",
    "Garantir <span> une croissance soutenue </span> même en <span> temps d'incertitude </span>",
  ],
  story: {
    subtitle: "Notre Story",
    desc: {
      more:"voir plus...",
      part1:
        "L'agence Brandwiser est née pour répondre à un gap dans l'industrie de branding. Au début quand on démarré notre activité avec Excellentia International Institute (l'entreprise mère), nous avons essayé de trouver une agence de branding pour nous aider à obtenir de clients payants. Tout ce que nous avons eu, c'était des promesses: une augmentation dans le Lead (Traffic) mais rien de concret et de palpable.",
      part2:
        "C'est à ce moment-là que nous avons décidé de prendre les choses en main “to do it the hard way” et ça a marché. Le rêve est devenu une réalité. Nous avons réussi à attirer une clientèle internationale grâce à notre stratégie baptisé BRANDWISER™, la même d'ailleurs que nous avons utilisé par la suite pour promouvoir nos clients.<br/><br/> Désormais, BRANDWISER n'est plus uniquement une stratégie…  Elle est devenue une entreprise indépendante qui se veut au service de tous ceux qui souhaitent exprimer leur unicité, bâtir des entreprises rentables, accéder à de nouveaux marchés, attirer plus de leads, gagner des clients, et réaliser leurs rêves.",
    },
  },
  ceo: {
    subtitle: "Message du fondateur et PDG Kais Gannouni:",
    desc:
      " “ Il m'est un plaisir de vous souhaiter, au nom de toute l'équipe, la bienvenue à notre agence BRANDWISER. Nous sommes là, œuvrant avec passion et dévouement, pour vous offrir une expérience de Branding unique, complète et personnalisée répondant exactement à vos besoins précis. ”",
  },
  vision: {
    subtitle: "Notre Mission",
    title: "Créer des marques qui <span> durent</span>",
  },
  mission: {
    subtitle: "Qui sommes nous?",
    title:
      "<br/> un <span> one-stop-shop </ span> <br/> pour tous vos besoins de branding",
  },
  values: {
    subtitle: "Nos valeurs",
    title: "Créativité, <br/> Passion, <br/> AUTHENTICITÉ, <br/> VALEUR AJOUTÉE, <br/> ET SURTOUT Pas de production EN masse",
  },
  team: {
    subtitle: "Notre equipe",
    title:
      "Nous sommes fiers d'avoir <br> une équipe <span> hautement qualifiée </span>",
  },
};
