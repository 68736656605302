import React from 'react'
import StartAssessment from "./assessment_start"
import { connect } from "react-redux"
const assessment_start = (props) => {
    return (
        <div>
            <StartAssessment {...props} />
        </div>
    )
}
const mapStateToProps = state => {
    return {
        result: state.assessmentReducer.result,
        sections:state.assessmentReducer.sections,
        
    }
}
export default connect(mapStateToProps)(assessment_start)
