import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Switch } from "antd";

const Head = ({ setFrensh, headData }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.scrollY === 0 ? setScroll(false) : setScroll(true)
    );
  }, []);
  return (
    <div className="assessment-bar">
        <p>{headData.subheader}
      <NavLink to="/assessment-start" className="assessment-btn">
        Assessment Tool <i className="fal fa-arrow-right" style={{ margin: " 0 10px" }}></i>
    </NavLink>
    </p>
      </div>
  );
};

export default Head;
