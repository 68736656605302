import React from "react";
import { Col, Row } from "antd";
import { brandwiser_data } from "../../data/brandwiser.js";
import ReactHtmlParser from "react-html-parser";
import { service_call } from "../../data/service_call";
import ServiceCall from "../../components/service_call";
import ServicesDetails from "../../components/services_details";
import CtaContact from "../../components/home_contact";
import HomeTestimonials from "../../components/home_testimonials";
import { useWindowSize } from "@react-hook/window-size";
const Brandwiser = ({ brandwiser }) => {
  const windowWidth = useWindowSize()[0];
  return (
    <div className="brandwiser">
      <div className="brandwiser_header"></div>
      <div className="brandwiser_content">
        <div className="brandwiser_content_title">
          {ReactHtmlParser(brandwiser.about.title)}
        </div>
        <div className="brandwiser_content_desc">
          <p>{ReactHtmlParser(brandwiser.about.desc)}</p>
        </div>
        <ServicesDetails serviceData={brandwiser} />

        <div className="brandwiser_content_meaning">
          <div className="brandwiser_content_meaning_title">
            {ReactHtmlParser(brandwiser.meaning.title)}
          </div>
          <div className="brandwiser_content_meaning_list">
            <Row>
              {windowWidth >= 599
                ? brandwiser.meaning.list.map((s) => (
                    <Col xs={24} sm={12}>
                      <div className="brandwiser_content_meaning_list_item">
                        {ReactHtmlParser(s)}
                      </div>
                    </Col>
                  ))
                : brandwiser.meaning.mobilelist.map((s) => (
                    <Col xs={24} sm={12}>
                      <div className="brandwiser_content_meaning_list_item">
                        {ReactHtmlParser(s)}
                      </div>
                    </Col>
                  ))}
            </Row>
          </div>
        </div>
      </div>
      <HomeTestimonials data={brandwiser.testemonials} />

      <CtaContact data={brandwiser.contact} />
    </div>
  );
};

export default Brandwiser;
