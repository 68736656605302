export const brandwiser = {
  about: {
    title: "<span>B.R.A.N.D.W.I.S.E.R ™</span>",
    desc:
      "<span> B.R.A.N.D.W.I.S.E.R ™, </span> est stratégie unique et expérimentée qui a permis à plusieurs entreprises, notamment à Excellentia International Institute de gagner en visibilité, crédibilité, profitabilité et de se positionner rapidement sur le marché international",
  },
  contact:{
    subtitle: "Vous avez une idée?",
    title: " <span>nous sommes là pour la réaliser.</span>",
    desc: " Demandez un devis gratuitement !",
    call: "Tendez la main maintenant!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Devis en ligne!",
  },
  services: {
    title: "Ce qui la rend <span> unique </span>",
    services: [
      {
        title:
          '<i class = "fal fa-sliders-h"> </i> <span> Personnalisable </span>',
        desc: [
          "Elle creuse dans les spécificités de chaque entreprise pour l'aider à créer une valeur ajoutée sûre et durable."
        ],
      },
      {
        title:  '<i class = "fal fa-pencil-ruler"> </i> <span> Stratégique </span>',
        desc: [
          "Elle est composée de dix étapes successives, interconnectées et faciles à suivre"
        ],
      },
      {
        title:
           '<i class = "fal fa-toolbox"> </i> <span> Pragmatique </span>',
        desc: [
          "Elle vous fournit tout un ensemble d'outils et de techniques simples mais pas simplistes."
        ],
      },
      {
        title:
          '  <i class="fal fa-random"></i> <span>Flexible</span>',
        desc: ["Vous pouvez la mettre en œuvre à votre rythme en fonction de votre disponibilité et ce, en achetant le pack Do It Yourself (DIY). Comme vous pouvez aussi télécharger le contenu tutoriels puis profiter du support et de l'accompagnement de l'un de nos coaches."] ,
      },
{
        title:
          '  <i class="fal fa-hand-point-up"></i> <span>Interactive</span>',
        desc: [
          "Un expert vous guidera tout au long du voyage, si vous vous procurez la version complète de B.R.A.N.D.W.I.S.E.R ™. Il travaillera en tandem avec vous jusqu'à ce que vous établissiez votre projet et qu'il commence à générer des revenus."
        ],
      },
{
        title:
           '<i class = "fal fa-wallet"> </i> <span> Abordable </span>',
        desc: [
          "Nos prix sont extrêmement compétitifs. On offre également gratuitement des services d'accompagnement qui, achetés séparément, seraient coûteux (création de site Web, gestion des médias sociaux, analyse de données)."
        ],
      },
    ],
  },
  meaning: {
    title: "Que signifie<span>  B.R.A.N.D.W.I.S.E.R™</span>",
    list: [
      "  <span>B</span>randing Readiness™",
      "  <span>W</span>ork out the Objections list",
      "  <span>R</span>efine your story",
      "  <span>I</span>nstall the CATCHES Funnel™",
      "  <span>A</span>ssess & Adjust your USP",
      "  <span>S</span>et a clear plan",
      "  <span>N</span>ourish Trust",
      "  <span>E</span>xecute the plan",
      "  <span>D</span>efine your Client Avatar",
      "  <span>R</span>eap the results",
    ],
    mobilelist: [
      "  <span>B</span>randing Readiness™",
      "  <span>R</span>efine your story",
      "  <span>A</span>ssess & Adjust your USP",
      "  <span>N</span>ourish Trust",
      "  <span>D</span>efine your Client Avatar",
      "  <span>W</span>ork out the Objections list",
      "  <span>I</span>nstall the CATCHES Funnel™",
      "  <span>S</span>et a clear plan",
      "  <span>E</span>xecute the plan",
      "  <span>R</span>eap the results",
    ],
  },
  testemonials: {
    subtitle: "Nos Clients",
    clients: [
      {
        name: "Sonia Dammak",
        post: "Directrice Communication d'une société pétrolière",
        text:
          "Vous êtes un nouvel entrepreneur ou vous souhaitez vous redéployer c'est l'outil qui vous faut !",
      },
     {
       name: "Afifa Zaoui Bouassida",
       post: "Marketing Manager at GCER Tunisia and Career Coach",
       text: "Tu vas bénéficier de l’aide et l’orientation de coachs attentifs, présents et surtout experts . Go ahead it’s a MAGIC Toolkits",
     },
      {
        name: "Ines Mahjoub Mbazaa",
       post:
          "Enseignante Universitaire/ Responsable Formation&Conseils 4C-ESCT",
        text:
          "je trouve cette approche à la fois pragmatique et rigoureuse. Je la recommande vivement !",
      },
      {
        name: "Salma DHOUIB",
        post: "Ingénieur concepteur en informatique, enseignante universitaire",
        text: "C’est un super outil qui touche à tous les niveaux d’entreprenariat.",
      },
      {
        name: "Ines Abidi",
        post: "Entrepreneur, graphiste",
        text:
          "En tant qu'entrepreneur, Je le recommande fortement pour réussir dans toutes sortes de branding.",
      },
     {
       name: "Hela zrida",
       post: "conseillère principale en ingénierie de formation",
       text: "N’hésitez pas à participer à cette formation, ne laissez personne vous freinez, ne laisser aucune contrainte vous stopper, formez-vous.",
     },
     {
       name: "Mounira Mbarki",
       post: "Enseignante universitaire , formatrice entrepreneuriat",
       text: "Brandwiser est une approche centrée sur l'humain , agile et emotionnellement intelligente.",
     },
      {
       name: "Houda Riffi",
        post: "Coach",
        text: "Brandwiser vous donnera un état d'esprit puissant et extraordinaire si vous voulez croire en votre impact et en votre succès.",
      },
      // {
      //   name: "Fadwa Mawas",
      //   post: "Coach",
      //   text: "Un programme plus que merveilleux et son contenu est très utile qui vous apprend à relever des défis difficiles pour commencer et gagner du temps et de l'argent",
      // },
      // {
      //   name: "Hinde Rachid",
      //   post: "Coach",
      //   text: "Un programme fluide et flexible qui vous permet d'atteindre vos objectifs.",
      // },
   ],
  },
};
