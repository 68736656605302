export const service_call = {
  call: {
    title: " Guarantee your success and <span>Take Action</span>",
    button: "Let's talk",
    animateme: {
      title: "Animate me",
      services: [
        "Video creation & editing",
        "Character creation & animation",
        "Video animation",
        "Logo animation",
        "Visual effects",
      ],
    },
    buildme: {
      title: "Build me",
      services: [
        "User-friendly Websites",
        "SEO set-up",
        "Email connection",
        "Mobile apps",
        "Support & maintenance",
      ],
    },
    marketme: {
      title: "Market me",
      services: [
        "Brand voice",
        "Branding strategy",
        "Customized social media calendar",
        "Data analysis",
        "Branding campaigns",
        "Community management"
      ],
    },
    designme: {
      title: "Design me",
      services: [
        "Visual Brand Identity",
        "Stationery items",
        "Social media packs",
        "Brochures, Banners",
        "Custom icons",
        "Packaging design",
      ],
    },
    brandwiser: {
      title: "Brandwiser",
      // services: [
      //   "  Video creation & editing",
      //   "  Character creation & animation",
      //   "  Video animation",
      //   "  Logo animation",
      //   "  Visual effects",
      // ],
    },
  },
};
