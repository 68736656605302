import React from "react";
import { Card } from "antd";
import { team } from "../data/team";
import ReactHtmlParser from "react-html-parser";
const { Meta } = Card;

const home_team = ({data}) => {
  return (
    <div className="home-team">
      <div className="home-team_content">
        <h6 className="home-team_content_subtitle">{data.subtitle}</h6>
        <h3 className="home-team_content_title">
          {ReactHtmlParser(data.title)}
        </h3>
      </div>
      <div className="home-team_team-members">
        {team.teamMembers.map((team) => (
          <a href={team.url} target="_blank" rel="noopener noreferrer">
            <Card
              style={{ width: 240 }}
              cover={
                <img
                  alt="example"
                  src={require(`../assets/images/team/${team.image}`)}
                />
              }
            >
              <Meta title={team.name} description={team.designation} />
            </Card>
          </a>
        ))}
      </div>
    </div>
  );
};

export default home_team;
