import React from "react";
import Layout from "./layouts";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import "./App.css";

import ScrollTop from "./components/scroll_top";
import Home from "./pages/home";
import Assessment from "./pages/assessment";
import DesignMe from "./pages/designme";
import MarketMe from "./pages/marketme";
import Contact from "./pages/contact";
import TenFree from "./pages/tenfree";
import Brandwiser from "./pages/brandwiser";
import BuildMe from "./pages/buildme";
import AssessmentFinish from "./pages/assessment_finish";
import AssessmentStart from "./pages/assessment_start";
import AnimateMe from "./pages/animateme";
import ServiceBill from "./pages/service_bill";
import About from "./pages/about";

import dataEn from "./data";
import dataFr from "./data/fr";

const App = ({ isFrensh }) => {
  // useEffect(() => {
  //  const data= isFrensh? require("./data/home"):require("./data/fr")
  // }, [])
  return (
    <Router className="App">
      <ScrollTop />
      <Layout headData={isFrensh ? dataFr.header : dataEn.header}>
        <Switch>
          <Route exact path="/">
            <Home home={isFrensh ? dataFr.home : dataEn.home} />
          </Route>
          <Route exact path="/assessment">
            <Assessment isFrensh={isFrensh} assessment={isFrensh ? dataFr.assessment : dataEn.assessment}/>
          </Route>
          <Route exact path="/assessment-start">
            <AssessmentStart assessment={isFrensh ? dataFr.assessment : dataEn.assessment}/>
          </Route>
          <Route exact path="/assessment-finish">
            <AssessmentFinish isFrensh={isFrensh} assessment={isFrensh ? dataFr.assessment : dataEn.assessment}/>
          </Route>
          <Route exact path="/service-request">
            <ServiceBill
              serviceBill={isFrensh ? dataFr.service_bill : dataEn.service_bill}
            />
          </Route>
          <Route exact path="/about">
            <About about={isFrensh ? dataFr.about : dataEn.about} />
          </Route>
          <Route exact path="/designme">
            <DesignMe design={isFrensh ? dataFr.design_me : dataEn.design_me} />
          </Route>
          <Route exact path="/buildme">
            <BuildMe build={isFrensh ? dataFr.build_me : dataEn.build_me} />
          </Route>
          <Route exact path="/marketme">
            <MarketMe market={isFrensh ? dataFr.market_me : dataEn.market_me} />
          </Route>
          <Route exact path="/animateme">
            <AnimateMe
              animate={isFrensh ? dataFr.animate_me : dataEn.animate_me}
            />
          </Route>
          <Route path="/contact">
            <Contact contact={isFrensh ? dataFr.contact : dataEn.contact} />
          </Route>
          <Route path="/free-visual-identity">
            <TenFree tenfree={isFrensh ? dataFr.tenfree : dataEn.tenfree} />
          </Route>
          <Route exact path="/brandwiser">
            <Brandwiser
              brandwiser={isFrensh ? dataFr.brandwiser : dataEn.brandwiser}
            />
          </Route>
        
        </Switch>
      </Layout>
    </Router>
  );
};

const mstp = (state) => ({ isFrensh: state.isFrenshReducer.isFrensh });

export default connect(mstp)(App);
