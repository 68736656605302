export const animate_me = {
  about: {
    title:
      " You would like to record <span>professional videos </span>?<br/> You need creative videos to<span> promote</span> your services, become more<span> visible</span>, and attract more <span>clients</span>?",
    desc:
      "AnimateMe is exactly what you are looking for. It is more than a place for video shooting or editing. It is a space for you to craft powerful visual attractive marketing messages. We provide you with our business consulting expertise to help you decide on what message to use and how to use it to highlight your Uniqueness Selling Proposition and win clients.",
  },
  contact: {
    subtitle: "Ready to start branding?",
    title: " <span>We'll get all your brand needs </span>taken care of.",
    desc: "",
    call: "Reach out Now!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Get a Quote!",
  },
  services: {
    title: "AnimateMe offers <span> custom</span> services",
    // services: [
    //   '  <i class="far fa-video"></i> <span>Video creation & editing</span>',
    //   '  <i class="far fa-user-edit"></i> <span>Character creation & animation</span>',
    //   '  <i class="far fa-film"></i> <span>Video animation</span>',
    //   '  <i class="far fa-money-bill-wave-alt"></i> <span>Logo animation</span>',
    //   '  <i class="far fa-eye"></i> <span>Visual effects</span>',
    // ],
    services: [
      {
        title:
          '  <i class="fal fa-video"></i> <span>Videography</span>',
        desc: [
          "Video shooting",
          "Video editing",
          "VFX: Visual effects"
        ],
      },
      {
        title: '  <i class="fal fa-camera-retro"></i> <span>Photography</span>',
        desc: [
          "Photo shoot",
          "Product Shoot",
          "LandScapes",
          "Post-production",
        ],
      },
      {
        title:
          '  <i class="fal fa-film"></i> <span>2D Animations</span>',
        desc: [
          "Informational video animation",
          "Educational video animation",
          "Promotional video animation",
          "Logo animation",
          "WhiteBoard Videos",
          "Character creation & animation"
        ],
      },
      {
        title:
          '  <i class="fal fa-podcast"></i> <span>Audio production </span>',
        desc: ["Jingles", "Sound effects","Voice recording"],
      },
    ],
    servicesBill: [
      '  Video creation & editing',
      '  Character creation & animation',
      '  Video animation',
      '  Logo animation',
      '  Visual effects',
    ],
  },

};
