import React from "react";
import { WhatsAppOutlined } from "@ant-design/icons";
import { contact } from "../data/contact";
import {Link} from "react-router-dom"
import ReactHtmlParser from "react-html-parser";
const home_contact = ({data}) => {
  return (
    <div className="home-contact home-container">
      <div className="home-contact_bg-image ">
        <div className="home-contact_desc">
          <div className="home-contact_desc_subtitle">
            {ReactHtmlParser(data.subtitle)}
          </div>
          <div className="home-contact_desc_title">
            {ReactHtmlParser(data.title)}
          </div>
          <span className="home-contact_desc_call-to-action">
          {ReactHtmlParser(data.desc)}
          </span>
        </div>
        <div className="home-contact_info">
           {/* <span className="home-contact_info_call-to-action">
          {ReactHtmlParser(data.desc)}
          </span> */}
          {/* <PhoneOutlined /> */}
          {/*<a className="home-contact_info_number">+216 51 500 097</a>
          <span className="home-contact_info_call-to-action">OR</span> */}
          <Link to="/service-request" className="home-contact_info_link">{ReactHtmlParser(data.btn)}</Link>
          <Link to="/service-request" className="home-contact_info_link whatsup"><WhatsAppOutlined />{ReactHtmlParser(data.phone)}</Link>
        </div>
      </div>
    </div>
  );
};

export default home_contact;
