export const brandwiser = {
  about: {
    title: " Learn More About Our <span>Most Successful Service</span>?",
    desc:
      "We provides you with our unique, proven and powerful <span>B.R.A.N.D.W.I.S.E.R™ Method,</span> the one that put Excellentia International Institute on the international map of the Coaching & Consulting Business within less than one year. <br/> Now helping other businesses from different sectors grow more <span>visible</span>, <span>notorious</span> and <span>profitable</span>.",
  },
  contact: {
    subtitle: "Ready to start branding?",
    title: " <span>We'll get all your brand needs </span>taken care of.",
    desc: "",
    call: "Reach out Now!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Get a Quote!",
  },
  services: {
    title: "What makes it <span> unique</span>",
    services: [
      {
        title: '  <i class="fal fa-sliders-h"></i> <span>Customizable</span>',
        desc: [
          "it digs dipper into the specificities of each business to help create an incomparable and unique added-value solving clients' real problems and generating steady incomes ",
        ],
      },
      {
        title:
          '  <i class="fal fa-pencil-ruler"></i> <span>A Strategic Approach</span>',
        desc: [
          "it is composed of  ten interconnected and easy to follow steps",
        ],
      },
      {
        title: '  <i class="fal fa-toolbox"></i> <span>Practical</span>',
        desc: [
          "It provides you with a whole set of simple but not simplistic tools & techniques.  ",
        ],
      },
      {
        title: '  <i class="fal fa-random"></i> <span>Flexible</span>',
        desc: [
          "you can do it at your own pace depending on your availability by purchasing the Do It Yourself (DIY) package. You can just download the material follow the tutorials and, if need be, reach out to the technical support to tweak additional things.",
        ],
      },
      {
        title:
          '  <i class="fal fa-hand-point-up"></i> <span>Interactive</span>',
        desc: [
          "an expert will walk you through the whole journey, if you purchase the full package version of it, and work hand in hand with you until you establish your project and get it to start generating incomes.",
        ],
      },
      {
        title: '  <i class="fal fa-wallet "></i> <span>Affordable</span>',
        desc: [
          "The price is extremely competitive and comes with extra free branding services that are usually expensive if bought apart (website creation, social media management, data analysis) ",
        ],
      },
    ],
  },
  meaning: {
    title: "The Meaning Behind<span>  B.R.A.N.D.W.I.S.E.R™</span>",
    list: [
      "  <span>B</span>randing Readiness™",
      "  <span>W</span>ork out the Objections list",
      "  <span>R</span>efine your story",
      "  <span>I</span>nstall the CATCHES Funnel™",
      "  <span>A</span>ssess & Adjust your USP",
      "  <span>S</span>et a clear plan",
      "  <span>N</span>ourish Trust",
      "  <span>E</span>xecute the plan",
      "  <span>D</span>efine your Client Avatar",
      "  <span>R</span>eap the results",
    ],
    mobilelist: [
      "  <span>B</span>randing Readiness™",
      "  <span>R</span>efine your story",
      "  <span>A</span>ssess & Adjust your USP",
      "  <span>N</span>ourish Trust",
      "  <span>D</span>efine your Client Avatar",
      "  <span>W</span>ork out the Objections list",
      "  <span>I</span>nstall the CATCHES Funnel™",
      "  <span>S</span>et a clear plan",
      "  <span>E</span>xecute the plan",
      "  <span>R</span>eap the results",
    ],
  },
  testemonials: {
    subtitle: "Our Clients",
    clients:[
      {
        name: "Sonia Dammak",
        post: "Communication Director of an oil company",
        text:
          "You're an enterpreneur? you want to succeed? this is exactly what you need ",
      },
      {
        name: "Afifa Zaoui Bouassida",
        post: "Marketing Manager at GCER Tunisia and Career Coach",
        text:
          "You will benefit from the help and guidance of attentive, present and above all expert coaches. Go ahead it's a MAGICAL Toolkit",
      },
      {
        name: "Ines Mahjoub Mbazaa",
        post:
          "University Teacher / 4C-ESCT Training & Consulting Manager",
        text:
          "I find this approach both pragmatic and rigorous. It really is a very rich experience that spurs action. I highly recommend it!",
      },
      {
        name: "Salma DHOUIB",
        post: "IT design engineer, university teacher",
        text:
          "It's quite a delightful, well-structured trip that I advise you not to miss the opportunity to experience it. ",
      },
       {
         name: "Ines Abidi",
         post: "Entrepreneur , Graphic Designer",
         text:
           "The added value of Brandwiser is that it is measurable and can warn me of the degree of advancement of each step of my business model.",
       },
      {
        name: "Hela zrida",
        post: "Senior Advisor in training engineering",
        text:
          "Do not hesitate to participate in this training, do not let anyone stop you, do not let any constraints stop you, train yourself.",
      },
      {
        name: "Mounira Mbarki",
        post:
          "University teacher, professional trainer in entrepreneurship.",
        text:
          "Brandwiser is a human-centered, agile and emotionally intelligent approach . ",
      },
       {
         name: "Houda Riffi",
         post: "Coach",
         text:
           "Brandwiser will give you  the powerful and extraordinary mindset program .",
       },
      //  {
      //    name: "Fadwa Mawas",
      //    post: "Coach",
      //    text:
      //      "A more than wonderful program and its content is beautiful and very useful that teaches you how to break difficult challenges to start off and win time and money",
      //  },
      //  {
      //    name: "Hinde Rachid",
      //    post: "Coach",
      //    text:
      //      "A smooth and flexible program that enables you to achieve your goals.",
      //  },
    ],
  },
};
