import React from "react";
import { services } from "../data/services";
import ReactPlayer from "react-player/youtube";
import ReactHtmlParser from "react-html-parser";
const home_service = ({data}) => {
  return (
    <div class="home-services">
      <div class="home-services_content">
        <h6 class="home-services_content_subtitle">{data.subtitle}</h6>
        <h3 class="home-services_content_title">
          {ReactHtmlParser(data.title)}
        </h3>
      </div>
      {/* <div className="home-services_card-container">
        {services.home.services.map((e) => (
          <Card>
            <div className="home-services_card-container_icon">

            <img
              src={require(`../assets/images/${e.logo}`)}
              alt="icon"
            />
            </div>
            <div className="home-services_card-container_title">{e.title}</div>
            <div className="home-services_card-container_desc">{e.desc}</div>
            <div className="arrow-icon">
              <ArrowRightOutlined />
            </div>
          </Card>
        ))}
      </div> */}
      <div class="home-services_video">
        {/* <ReactPlayer
          url="https://youtu.be/rzpn2rE7AGg"
          width="100%"
          height="90%"
          controls="true"
        /> */}
          <iframe
          id="brandwiser_intro"
          width="100%"
          height="90%"
          src={data.video}
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default home_service;
