import * as CONSTANT from "./actionTypes";

const initState = {
  service: "",
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case CONSTANT.UPDATE_SERVICE:
      return {
        ...state,
        service: action.service,
      };
    default:
      return state;
  }
};
export default reducer;
