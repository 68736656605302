import React from "react";
import {contact} from "../../data/contact"
const footer = () => {
  return (
    <div className="footer-area-wrapper">
                <div className="logo-footer">
        <img
          id="logo"
          src={require("../../assets/images/logo/logo-dark.png")}
          alt="Brandwiser"
        />
      </div>
          <span className="copyright-text">
        © 2020 Brandwiser. All Rights Reserved.
      </span>
  
  
      <ul className="social-media">
        <li className="social-link ">
          <a href={contact.youtube} target="_blank" aria-label="Youtube" rel="noopener noreferrer" >
            <i className="fab fa-youtube link-icon"></i>
          </a>
        </li>
        <li className="social-link ">
          <a href={contact.facebook} target="_blank" aria-label="Facebook" rel="noopener noreferrer">
            <i className="fab fa-facebook-f link-icon"></i>
          </a>
        </li>
        <li className="social-link ">
          <a href={contact.instagram} target="_blank" aria-label="Instagram" rel="noopener noreferrer">
            <i className="fab fa-instagram link-icon"></i>
          </a>
        </li>
        <li className="social-link ">
          <a href={contact.linkedin} target="_blank" aria-label="Linkedin" rel="noopener noreferrer">
            <i className="fab fa-linkedin link-icon"></i>
          </a>
        </li>
      </ul>


    </div>
  );
};

export default footer;
