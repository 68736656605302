import React from 'react'
import AssessmentTools from './assessment'
import AssessmentMobile from './assessment_mobile'
import { connect } from "react-redux"
import * as actions  from "../../redux/assessment/actions"
import { useWindowSize } from "@react-hook/window-size";
const Assessment = (props) => {
    const windowWidth = useWindowSize()[0];
    return (
        windowWidth >= 1200 ? 
            <AssessmentTools {...props} />
            :
            <AssessmentMobile {...props} />
       
    )
}
const mapStateToProps = state => {
    return {
        result: state.assessmentReducer.result,
        sections:state.assessmentReducer.sections,
        quests:state.assessmentReducer.quests,
        quests:state.assessmentReducer.assessmentTab,
        
    }
}
const mapDispatchToProps = dispatch => {
    return {
        calcResult: () => dispatch(actions.calcResult()),
        reset: () => dispatch(actions.reset()),
        sectionResult: (x,y) => dispatch(actions.sectionResult(x,y)),
        updateChoice: (x,y) => dispatch(actions.updateChoice(x,y))
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Assessment)
