export const assessment = {
  start: {
    title: "Welcome to the Assessment tool",
    subtitle: "Add your email to start",
    desc: "<span>**</span> Subscribe to get updated with our newest features",
    button: "START",
  },
  finish: {
    title1: "You are ",
    title2: " Ready",
  },
  brandwiser: {
    text:
      // "Ready to start your business?<br/> Book a free discovery session to discuss your results <br/> Pick a date and book a session to meet our experts",
      "Ready to start your business?<br/> Book a free discovery session to discuss your results",
    button: "Book now!",
  },
  assessment: {
      title:"Assessment tools",
      subtitle:"Rate yourself from 0 to 10 on the following sentences",
      
  },
};
