export const home = {
  header: {
    prom1: {
      img: "promotion1.jpg",
      desc:
        "Celebrating the launch of the Brandwiser Agency, We are offering you a ONE TIME OFFER of 50% OFF all the Brandwiser services for the first month.",
      call:
        " Offer available till 30.10.2020 claim now! <i class='fal fa-arrow-right' ></i>",
    },
    prom2: {
      img: "promotion2.jpg",
      desc:
        "Celebrating the launch of the Brandwiser Agency, You have the chance to win a complete visual identity design for your brand from logo to color palette to typography. Be among the 10 people who would benefit from this gift by signing up here.",
      call:
        "This is a limited offer so grab your gift now! <i class='fal fa-arrow-right'></i>",
    },
  },
  about: {
    subtitle: "who We Are",
    title: "one <br/> stop <br/>shop ",
    desc:
      "<span> Brandwiser </span>is a full-service branding agency. we believe every brand is different. That’s why we provide you with a customized experience that will cover all your branding needs from idea to execution.",
  },
  service: {
    subtitle: "Our services",
    video:
      "https://www.youtube-nocookie.com/embed/nk6JbdXXbH0?&rel=0&showinfo=0&iv_load_policy=3",
    title:
      "<span>highly-<br/>tailored</span> services, For your <span> very specific</span> brand.",
  },
  contact: {
    subtitle: "Ready to start branding?",
    title: " <span>We'll get all your brand needs </span>taken care of.",
    desc: "",
    call: "Reach out Now!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Get a Quote!",
  },
  team: {
    subtitle: "Our team",
    title:
      "We pride ourselves on having <br>a <span>highly-skilled</span> team",
  },
  testemonials: {
    subtitle:"Our Clients",
    clients:[
      {
        name: "Sonia Dammak",
        post: "Communication Director of an oil company",
        text:
          "You're an enterpreneur? you want to succeed? this is exactly what you need ",
      },
      {
        name: "Afifa Zaoui Bouassida",
        post: "Marketing Manager at GCER Tunisia and Career Coach",
        text:
          "You will benefit from the help and guidance of attentive, present and above all expert coaches. Go ahead it's a MAGICAL Toolkit",
      },
      {
        name: "Ines Mahjoub Mbazaa",
        post:
          "University Teacher / 4C-ESCT Training & Consulting Manager",
        text:
          "I find this approach both pragmatic and rigorous. It really is a very rich experience that spurs action. I highly recommend it!",
      },
      {
        name: "Salma DHOUIB",
        post: "IT design engineer, university teacher",
        text:
          "It's quite a delightful, well-structured trip that I advise you not to miss the opportunity to experience it. ",
      },
       {
         name: "Ines Abidi",
         post: "Entrepreneur , Graphic Designer",
         text:
           "The added value of Brandwiser is that it is measurable and can warn me of the degree of advancement of each step of my business model.",
       },
      {
        name: "Hela zrida",
        post: "Senior Advisor in training engineering",
        text:
          "Do not hesitate to participate in this training, do not let anyone stop you, do not let any constraints stop you, train yourself.",
      },
      {
        name: "Mounira Mbarki",
        post:
          "University teacher, professional trainer in entrepreneurship.",
        text:
          "Brandwiser is a human-centered, agile and emotionally intelligent approach . ",
      },
       {
         name: "Houda Riffi",
         post: "Coach",
         text:
           "Brandwiser will give you  the powerful and extraordinary mindset program .",
       },
      //  {
      //    name: "Fadwa Mawas",
      //    post: "Coach",
      //    text:
      //      "A more than wonderful program and its content is beautiful and very useful that teaches you how to break difficult challenges to start off and win time and money",
      //  },
      //  {
      //    name: "Hinde Rachid",
      //    post: "Coach",
      //    text:
      //      "A smooth and flexible program that enables you to achieve your goals.",
      //  },
    ],}
};
