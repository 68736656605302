export const tenfree = {
  title:
    "Be among<br/><span> the first 10 people</span>  who will win <br/><span>a complete Visual Identity Design</span><br/> by signing up here! Offer available until 15.10.2020.",
  phone: "+216 51 500 097",
  email: "contact@brandwiseragency.com",
  facebook: "https://fb.me/Brandwiseragency",
  linkedin: "https://www.linkedin.com/company/brandwiser-agency/",
  youtube:
    "https://www.youtube.com/channel/UC8WjyYE35l5PwNvxTAHE1-g?view_as=subscriber",
  instagram: "https://www.instagram.com/brandwiseragency/",
  btn: "Submit",
};
