import * as CONSTANT from './actionTypes'
export const updateChoice = (questIndex,questValue) => {
    return {
        type: CONSTANT.UPDATE_CHOICE,
        questIndex,
        questValue
    }
}

export const calcResult = (payload) => {
    return {
        type: CONSTANT.CALC_RESULT,
        payload
    }
}
export const sectionResult = (quest,index) => {
    return {
        type: CONSTANT.SECTION_RESULT,
      quest,
      index
    }
}

export const reset = (payload) => {
    return {
        type: CONSTANT.RESET,
        payload
    }
}