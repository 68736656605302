import React from "react";
import { about } from "../data/about";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { motion } from "framer-motion"
const home_about = ({data}) => {
  return (
    <div className="home-about home-container">
      <h6 className="home-about_content_subtitle">{data.subtitle}</h6>
      <div className="home-about_content">
        {/* <motion.h3
        initial={{ opacity: 0, x: 0 }}
        whileHover={{ x: 50,opacity: 1 }}
         transition={{ duration: 0.5 }}
          className="home-about_content_title"
        > */}
          <h3 className="home-about_content_title">
          {ReactHtmlParser(data.title)}
          </h3>
        {/* </motion.h3> */}
        {/* <img src="https://www.suziqcreations.com/wp-content/uploads/News-animation2.gif" alt=""/> */}
        <p className="home-about_desc">{ReactHtmlParser(data.desc)}</p>
      </div>
    </div>
  );
};

export default home_about;
