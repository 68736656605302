export const build_me = {
  about: {
    title:
      "Votre site Web n'est pas <span> attractif </span>? Il est lourd ? Il ne met pas en valeur l'<span>originalité</span> de vos produits ? Il ne génère pas le nombre de <span> trafique </span> que vous espérez avoir ?",
    desc:
      "BuildMe est exactement ce que vous cherchez. C'est plus qu'un espace de création des sites web. C'est l'endroit idéal pour donner libre cours à votre imagination et fignoler un message de marketing percutant et attractif. Nous mettons à votre disposition notre expertise en consulting pour vous aider à mettre en valeur votre Unique Selling Propostion et attirer plus de clientèle.",
  },
  contact:{
    subtitle: "Vous avez une idée?",
    title: " <span>nous sommes là pour la réaliser.</span>",
    desc: " Demandez un devis gratuitement !",
    call: "Tendez la main maintenant!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Devis en ligne!",
  },
  services: {
    title: "BuildMe propose des services <span> personnalisés </span>",
    services: [
      {
        title: '  <i class="fal fa-desktop-alt"></i> <span> Sites Web </span> ',
        desc: [
          "Vitrine basique",
          "Vitrine personnalisée",
          "Page de destination",
          "Commerce électronique",
        ],
      },
      {
        title: '<i class = "fal fa-chart-line"> </i> <span> SEO </span>',
        desc: [
          "Création de liens",
          "Optimisation de site Web",
          "Optimisation du plan de site",
          "Configuration des outils du moteur de recherche",
          "Trafic et classement",
        ],
      },
      {
        title: '<i class = "fal fa-mobile"> </i> <span> Applications </span>',
        desc: [
          "Application Web",
          "Application mobile",
          "Application de bureau",
        ],
      },
      {
        title: '<i class = "fal fa-envelope-open-text"> </i> <span> E-mail </span>',
        desc: [
          "Automatisation des e-mails",
          "Configuration des e-mails",
          "Signature d'e-mails",
        ],
      },
      {
        title: '<i class = "fal fa-server"> </i> <span> DevOps </span>',
        desc: ["Hébergement serveur", "Nom de domaine", "Déploiement"],
      },
      {
        title:
          '<i class = "fal fa-tools"> </i> <span> Assistance et maintenance </span>',
        desc: ["HelpDesk Support", "Management Server", "Team Tech dédié"],
      },
    ],
  },
};
