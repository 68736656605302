export const market_me = {
  about: {
    title:
      " You are struggling with <span>marketing your business</span>?<br /> The events you organize go <span>unnoticed</span>?",
    desc:
      "MarketMe is exactly what you are looking for. It is more than a place for marketing. It is a space for you to craft powerful visual attractive marketing messages. We provide you with our business consulting expertise to help you decide on what message to use and how to use it to highlight your Uniqueness Selling Proposition and win clients.",
  },
  contact: {
    subtitle: "Ready to start branding?",
    title: " <span>We'll get all your brand needs </span>taken care of.",
    desc: "",
    call: "Reach out Now!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Get a Quote!",
  },
  services: {
    title: "MarketMe offers <span> custom</span> services",
    services: [
      {
        title: '  <i class="fal fa-bullhorn"></i> <span>Brand voice</span>',
        desc: ["Naming", "tagline/slogan", "Elevator Pitch", "Copywriting "],
      },
      {
        title:
          '  <i class="fal fa-pencil-ruler"></i> <span>Branding strategy</span>',
        desc: [
          "Brand mission statement",
          "Market Research",
          "Analysis (SWOT/PESTLE)",
          "Brand Positioning",
          "Value Proposition",
          "Brand Architecture",
          "Customer avatar",
          "Recommendations",
        ],
      },
      {
        title:
          '  <i class="fal fa-calendar-alt"></i> <span>Customized social media calendar</span>',
        desc: ["Social media post ideas","Calendar to organize your social media posts"],
      },
      {
        title:
          '  <i class="fal fa-analytics"></i> <span>Data analysis</span>',
        desc: ["Social media performance analysis","Website performance analysis","Website Health Audit"],
      },
      {
        title:
          '  <i class="fal fa-sticky-note"></i> <span>Branding campaigns</span>',
        desc: [
          "Brand Launch",
          "New Product Launch",
          "Brand Awareness ",
        
        ],
      },
      {
        title:
          ' <i class="fal fa-comments"></i> <span>Community management</span>',
        desc: ["Customized calendar","Posts Scheduling","Creator studio","Groups management"],
      },
    ],
  },
};
