export const home = {
  header: {
    prom1: {
      img: "promotion1.jpg",
      desc:
        "Pour célébrer le lancement de l'agence Brandwiser, nous vous proposons une OFFRE UNIQUE de 50% de réduction sur tous les services Brandwiser pour le premier mois.",
      call:
        " Offre valable jusqu'au 30.10.2020 <i class='fal fa-arrow-right' ></i>",
    },
    prom2: {
      img: "promotion2.jpg",
      desc:
        "En célébrant le lancement de l'agence Brandwiser, vous avez la chance de gagner une identité visuelle complète pour votre marque. Faites partie des 10 personnes qui bénéficieraient de ce cadeau en vous inscrivant ici.",
      call:
        "Ceci est une offre limitée, alors prenez votre cadeau maintenant! <i class='fal fa-arrow-right'></i>",
    },
  },
  about: {
    subtitle: "Qui sommes-nous ?",
    title: "one <br/> stop <br/>shop ",
    desc:
      "<span> Brandwiser </span> est une agence de branding à service complet. Nous croyons que chaque marque est unique. Nos services sont personnalisés. Nous répondons à tous vos besoins de branding:<span> de l’idée à l’exécution</span>.",
  },
  service: {
    subtitle: "Nos services",
    video:
      "https://www.youtube-nocookie.com/embed/926dSDVwMpQ?&rel=0&showinfo=0&iv_load_policy=3",
    title: " <span>Idées créatives </span>pour UN BRANDING UNIQUE",
  },
  contact: {
    subtitle: "Vous avez une idée?",
    title: " <span>nous sommes là pour la réaliser.</span>",
    desc: " Demandez un devis gratuitement !",
    call: "Tendez la main maintenant!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Devis en ligne!",
  },
  team: {
    subtitle: "Notre equipe",
    title:
      "Nous sommes fiers d'avoir <br> une équipe <span> diversifiée et créative </span>",
  },
  testemonials: {
    subtitle: "Nos Clients",
    clients: [
      {
        name: "Sonia Dammak",
        post: "Directrice Communication d'une société pétrolière",
        text:
          "Vous êtes un nouvel entrepreneur ou vous souhaitez vous redéployer c'est l'outil qui vous faut !",
      },
     {
       name: "Afifa Zaoui Bouassida",
       post: "Marketing Manager at GCER Tunisia and Career Coach",
       text: "Tu vas bénéficier de l’aide et l’orientation de coachs attentifs, présents et surtout experts . Go ahead it’s a MAGIC Toolkits",
     },
      {
        name: "Ines Mahjoub Mbazaa",
       post:
          "Enseignante Universitaire/ Responsable Formation&Conseils 4C-ESCT",
        text:
          "je trouve cette approche à la fois pragmatique et rigoureuse. Je la recommande vivement !",
      },
      {
        name: "Salma DHOUIB",
        post: "Ingénieur concepteur en informatique, enseignante universitaire",
        text: "C’est un super outil qui touche à tous les niveaux d’entreprenariat.",
      },
      {
        name: "Ines Abidi",
        post: "Entrepreneur, graphiste",
        text:
          "En tant qu'entrepreneur, Je le recommande fortement pour réussir dans toutes sortes de branding.",
      },
     {
       name: "Hela zrida",
       post: "conseillère principale en ingénierie de formation",
       text: "N’hésitez pas à participer à cette formation, ne laissez personne vous freinez, ne laisser aucune contrainte vous stopper, formez-vous.",
     },
     {
       name: "Mounira Mbarki",
       post: "Enseignante universitaire , formatrice entrepreneuriat",
       text: "Brandwiser est une approche centrée sur l'humain , agile et emotionnellement intelligente.",
     },
      {
       name: "Houda Riffi",
        post: "Coach",
        text: "Brandwiser vous donnera un état d'esprit puissant et extraordinaire si vous voulez croire en votre impact et en votre succès.",
      },
      // {
      //   name: "Fadwa Mawas",
      //   post: "Coach",
      //   text: "Un programme plus que merveilleux et son contenu est très utile qui vous apprend à relever des défis difficiles pour commencer et gagner du temps et de l'argent",
      // },
      // {
      //   name: "Hinde Rachid",
      //   post: "Coach",
      //   text: "Un programme fluide et flexible qui vous permet d'atteindre vos objectifs.",
      // },
   ],
  },
};
