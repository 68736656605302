export const team = {
  subtitle: "Notre équipe",
  title: "Nous sommes fiers d'avoir <br> une équipe <span> hautement qualifiée </span>",
  teamMembers: [
    {
      id: 1,
      image: "kais.png",
      name: "Kais Gannouni",
      designation: "PDG",
      fbUrl: "https://www.facebook.com",
      twitterUrl: "https://twitter.com",
      instagramUrl: "https://www.instagram.com",
    },
    {
      id: 2,
      image: "maha.png",
      name: "Maha Yarmani",
      designation: "Directeur du marketing numérique",
      fbUrl: "https://www.facebook.com",
      twitterUrl: "https://twitter.com",
      instagramUrl: "https://www.instagram.com",
    },
    {
      id: 3,
      image: "oussama.png",
      name: "Oussema Nefzi",
      designation: "Directeur artistique",
      fbUrl: "https://www.facebook.com",
      twitterUrl: "https://twitter.com",
      instagramUrl: "https://www.instagram.com",
    },

    {
      id: 4,
      image: "sha.png",
      name: "Shahrazed Sakhri",
      designation: " Développeur web",
      fbUrl: "https://www.facebook.com",
      twitterUrl: "https://twitter.com",
      instagramUrl: "https://www.instagram.com",
    },

  ],
};
