export const service_bill = {
  title: "Fais le pas et garantis ton <span>succès</span>",
  subtitle: "** Personnalisez votre demande en cochant sur les services en dessous en fonction de vos besoins de Branding",
  desc: "",
  btn:"Demander un devis",
  person:"Vous êtes:",
  business:"Spécificités de votre projet:",
  form:{
    name:"Nom",
    email: "E-mail",
    phone: "Telephone",
    industry: "Industrie",
    brand: "Nom du Marque",
    budget: "Budget",
    message: "Message",
  },
  animate: {
    title: "Animate Me",
    list: [
      {
        title: "Vidéographie",
        desc: ["Tournage vidéo", "Montage vidéo", "VFX: Effets visuels"],
      },
      {
        title: "Photographie",
        desc: ["Photoshoot","Photoshoot du produit", "Paysages", "Post-production",],
      },
      {
        title: "Animations 2D",
        desc: [
          "Animation vidéo informative ",
          "Animation vidéo éducative",
          "Animation vidéo promotionnelle",
          "Animation de logo",
          "Vidéos WhiteBoard",
          "Création et animation du caractère",
        ],
      },
      {
        title: "Production audio",
        desc: ["Jingles", "Effets sonores", "Enregistrement vocal"],
      },
    ],
  },
  build: {
    title: "Build Me",
    list: [
      {
        title: "Sites Web",
        desc: [
          "Vitrine basique",
          "Vitrine personnalisée",
          "Page de destination",
          "Commerce électronique",
        ],
      },
      {
        title: "SEO",
        desc: [
          "Création de liens",
          "Optimisation de site Web",
          "Optimisation du plan de site",
          "Configuration des outils du moteur de recherche",
          "Trafic et classement",
        ],
      },
      {
        title: "Applications",
        desc: ["Application Web",
          "Application mobile",
          "Application de bureau"],
      },
      {
        title: "E-mail ",
        desc: ["Automatisation des e-mails",
          "Configuration des e-mails",
          "Signature d'e-mails"],
      },
      {
        title: "DevOps",
        desc: ["Server hosting", "Domain Name", "Deployment"],
      },
      {
        title: "Assistance et maintenance",
        desc: ["HelpDesk Support", "Management Server", "Team Tech dédié"],
      },
    ],
  },
  design: {
    title: "Design Me",
    list: [
      {
        title: "Identité visuelle de la marque",
        desc: [
          "Logo",
          "Typographie",
          "Palette de couleurs",
          "Imagerie: aspects visuels"
        ],
      },
      {
        title: "UX/UI",
        desc: [
          "Création de sites web",
          "Conception d'applications mobiles",
          "Planification de l'architecture de l'information",
          "Wireframe",
          "Maquettes",
        ],
      },
      {
        title: "Articles de papeterie",
        desc: [
          "carte de visite",
          "En-tête de lettre",
          "Calendriers",
          "Des stylos",
          "Dossier de présentation",
        ],
      },
      {
        title: "Éléments de médias sociaux",
        desc: ["Affiches", "Couvertures"],
      },
      {
        title: "Bannières, brochures",
        desc: [
          "Brochures produits et événements",
          "Brochures événements",
          "bannières publicitaires",
          "Bannières de site Web",
          "Roll ups",
          "Bannières suspendues et suspendues",
        ],
      },
      {
        title: "Icônes personnalisées",
        desc: ["Icônes de site Web", "Icônes de présentation", "Icônes de médias sociaux"],
      },
      {
        title: "Modèles commerciaux",
        desc: ["Brochures", "Présentations", "Rapports", "Emails"],
      },
      {
        title: "Emballage du produit",
        desc: ["Sacs", "Sacs", "Bouteilles"],
      },
    ],
  },
  market: {
    title: "Market Me",
    list: [
      {
        title: "Voix de marque",
        desc: ["Dénomination", "slogan", "Elevator Pitch", "Rédaction"],
      },
      {
        title: "Stratégie de marque",
        desc: [
          "Déclaration de mission de marque",
          "Étude de marché",
          "Analyse (SWOT / PESTLE)",
          "Positionnement de la marque",
          "Proposition de valeur",
          "Architecture de marque",
          "Avatar du client",
          "Recommandations",
        ],
      },
      {
        title: "Calendrier des réseaux sociaux personnalisé",
        desc: [
          "Idées de publications sur les réseaux sociaux", "Calendrier pour organiser vos publications sur les réseaux sociaux"
        ],
      },
      {
        title: "Analyse des données",
        desc: [
          "Analyse des performances des médias sociaux", "Analyse des performances du site Web", "Audit de santé du site Web"
        ],
      },
      {
        title: "Campagnes de branding",
        desc: ["Lancement de marque",
          "Lancement d'un nouveau produit",
          "Notoriété de la marque",],
      },
      {
        title: "Gestion de la communauté",
        desc: [
          "Calendrier personnalisé", "Planification des publications", "Creator studio", "Gestion des groupes"
        ],
      },
    ],
  },

  brandwiser: {
    title: "B.R.A.N.D.W.I.S.E.R",
    list: [
      "Personnalisable",
      "Stratégique",
      "Pragmatique",
      "Flexible",
      "Interactive",
      "Abordable",
    ],
  },
};