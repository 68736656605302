import React from "react";
import { Col, Row } from "antd";
import { animate_me } from "../../data/animate-me.js";
import ReactHtmlParser from "react-html-parser";
import { service_call } from "../../data/service_call";
import ServiceCall from "../../components/service_call";
import ServicesDetails from "../../components/services_details";
import CtaContact from "../../components/home_contact";

const Animateme = ({ animate }) => {
  return (
    <div className="animate-me">
      <div className="infotechno-video-bg_play"></div>
      <div className="animate-me_header"></div>
      <div className="animate-me_content">
        <div className="animate-me_content_title">
          {ReactHtmlParser(animate.about.title)}
        </div>
        <div className="animate-me_content_desc">
          <p>{ReactHtmlParser(animate.about.desc)}</p>
        </div>

        <ServicesDetails serviceData={animate} />
      </div>
      {/* <ServiceCall serviceName={service_call.call.animateme} /> */}
      <CtaContact data={animate.contact}/>
    </div>
  );
};

export default Animateme;
