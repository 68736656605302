export const design_me = {
  about: {
    title:
      " You are struggling with the <span>graphics</span>?<br /> Your brochures are <span>old-fashioned</span>?",
    desc:
      "DesignMe is exactly what you are looking for. It is more than a place for graphic design. It is a space for you to craft powerful visual attractive marketing messages. We provide you with our business consulting expertise to help you decide on what message to use and how to use it to highlight your Uniqueness Selling Proposition and win clients.",
  },
  contact: {
    subtitle: "Ready to start branding?",
    title: " <span>We'll get all your brand needs </span>taken care of.",
    desc: "",
    call: "Reach out Now!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Get a Quote!",
  },
  services: {
    title: "DesignMe offers <span> custom</span> services",
    services: [
      {
        title:
          '  <i class="fal fa-copyright"></i> <span>Visual Brand Identity</span>',
        desc: [
          "Logo",
          "Typography",
          "Color palette",
          "Imagery: visual aspects",
        ],
      },
      {
        title: '  <i class="fal fa-object-group"></i> <span>UX/UI</span>',
        desc: [
          "Web design",
          "Mobile app design",
          "Information Architecture Planning",
          "Wireframe",
          "Mockups",
        ],
      },
      {
        title:
          '  <i class="fal fa-pencil-ruler"></i> <span>Stationery items</span>',
        desc: [
          "business card",
          "Letterhead",
          "Calendars",
          "Pens",
          "Presentation folder",
        ],
      },
      {
        title:
          '  <i class="fal fa-share-alt"></i> <span>Social media assets</span>',
        desc: ["Posters", "Covers"],
      },
      {
        title:
          '  <i class="fal fa-scroll"></i> <span>Banners, Brochures</span>',
        desc: [
          "Products and events brochures",
          "Events brochures",
          "ads banners",
          "Website banners",
          "Roll ups",
          "Suspended & Hanging Banners",
        ],
      },
      {
        title: ' <i class="fal fa-sticky-note"></i> <span>Iconography</span>',
        desc: ["Website icons", "Presentation icons", "Social media icons"],
      },
      {
        title:
          ' <i class="fal fa-folders"></i> <span>Business Templates</span>',
        desc: ["Booklets", "Presentations", "Reports", "Emails"],
      },
      {
        title:
          ' <i class="fal fa-box-open"></i> <span>Product Packaging</span>',
        desc: ["Bags", "Boxes", "Bottles"],
      },
    ],
  },
};
