import React from "react";
import { service_call } from "../data/service_call";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateService } from "../redux/contact-call/actions";
const Service_call = () => {

  const isNumber = (x) => {
    return /^\d+$/g.test(Number(x));
  };

  return (
    <div className="service-call">
       <div className="service-call_title">
        {ReactHtmlParser(service_call.call.title)}
      </div>
    
       <Link to={`/service-request`} className="service-call_content_button">
            <i class="far fa-comment-alt-dots"></i>
            <div>{ReactHtmlParser(service_call.call.button)}</div>
          </Link>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateService: (x) => dispatch(updateService(x)),
  };
};
export default connect(null, mapDispatchToProps)(Service_call);
