import React, { useState } from "react";
import { Input, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import emailjs from "emailjs-com";
const Assessment_start = ({ assessment }) => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(e);
    emailjs
      .sendForm(
        "brandwiser_mail",
        "brandwiser_template",
        e.target,
        "user_LO2qAhi66N5K4CemNcmhv"
      )
      .then(
        (result) => {
          console.log("sent");
          history.push("/assessment");
        },
        (error) => {
          console.log("not sent");
        }
      );
    e.target.reset();
  };
  return (
    <div className="assessment-start">
      <h2>{ReactHtmlParser(assessment.start.title)}</h2>
      <div className="mail">
        <p>{ReactHtmlParser(assessment.start.subtitle)}</p>
        <form onSubmit={sendEmail}>
          <input
            type="hidden"
            name="subject"
            value={"New Assessment Tool Subscriber"}
          />
          <input
            type="email"
            name="email"
            className="ant-input"
            placeholder="your email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <p className="subs">{ReactHtmlParser(assessment.start.desc)}</p>
          <button
            disabled={
              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email) ? false : true
            }
            type="submit"
            className="ant-btn"
          >
            {/* <Link to="/assessment"> */}
            {ReactHtmlParser(assessment.start.button)}
            {/* </Link> */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Assessment_start;
