export const design_me = {
  about: {
    title:
      " Vos <span> visuels </ span> et  <span> graphiques </ span>  non pas l'air d'être vraiment professionnels? <br/> Vos brochures ne sont pas <span> attractives </ span>?",
    desc:
      "DesignMe est exactement ce que vous cherchez. C'est plus qu'un espace de création de graphiques visuels. C'est l'endroit idéal pour donner libre cours à votre imagination et fignoler un message de marketing percutant et attractif. Nous mettons à votre disposition notre expertise en consulting pour vous aider à mettre en valeur votre Unique Selling Propostion et attirer plus de clientèle.",
  },
  contact:{
    subtitle: "Vous avez une idée?",
    title: " <span>nous sommes là pour la réaliser.</span>",
    desc: " Demandez un devis gratuitement !",
    call: "Tendez la main maintenant!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Devis en ligne!",
  },
  services: {
    title: "DesignMe vous offre des services <span> personnalisés </span>",
    services: [
      {
        title:
          '  <i class = "fal fa-copyright"> </i> <span> Identité visuelle de la marque </span> ',
        desc: [
          "Logo",
          "Typographie",
          "Palette de couleurs",
          "Imagerie: aspects visuels",
        ],
      },
      {
        title: '<i class = "fal fa-object-group"> </i> <span> UX / UI </span>',
        desc: [
          "Création de sites web",
          "Conception d'applications mobiles",
          "Planification de l'architecture de l'information",
          "Wireframe",
          "Maquettes",
        ],
      },
      {
        title:
          '<i class = "fal fa-pencil-ruler"> </i> <span> Articles de papeterie </span>',
        desc: [
          "carte de visite",
          "En-tête de lettre",
          "Calendriers",
          "Des stylos",
          "Dossier de présentation",
        ],
      },
      {
        title:
          '<i class = "fal fa-share-alt"> </i> <span> Éléments de médias sociaux </span>',
        desc: ["Affiches", "Couvertures"],
      },
      {
        title:
          '<i class = "fal fa-scroll"> </i> <span> Bannières, brochures </span>',
        desc: [
          "Brochures produits et événements",
          "Brochures événements",
          "bannières publicitaires",
          "Bannières de site Web",
          "Roll ups",
          "Bannières suspendues et suspendues",
        ],
      },
      {
        title: '<i class = "fal fa-sticky-note"> </i> <span> Icônes personnalisées </span>',
        desc: ["Icônes de site Web", "Icônes de présentation", "Icônes de médias sociaux"],
      },
      {
        title:
          '<i class = "fal fa-folders"> </i> <span> Modèles commerciaux </span>',
        desc: ["Brochures", "Présentations", "Rapports", "Emails"],
      },
      {
        title:
          '<i class = "fal fa-box-open"> </i> <span> Emballage du produit </span>',
        desc: ["Sacs", "Box", "Bouteilles"],
      },
    ],
  },
};
