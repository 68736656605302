import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "antd";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import emailjs from "emailjs-com";

const Contact = ({ tenfree }) => {
  const [mailForm, setMailForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    subject:"10 Free Visual Identity"
  });
  const sendEmail = (e) => {
    e.preventDefault();
    Object.values(mailForm).filter((e) => e !== "").length === 0
      ? Modal.error({
          title: "OOPS!... Form is empty!",
          content: (
            <div className="modal-error">
              <div>
                <img
                  src={require("../../assets/images/Gifs/Get_in_touch.gif")}
                  alt="animated"
                />
              </div>
              <p> Please make sure to fill all the fields</p>
            </div>
          ),
          onOk() {},
        })
      : emailjs
          .sendForm(
            "brandwiser_mail",
            "brandwiser_template",
            e.target,
            "user_LO2qAhi66N5K4CemNcmhv"
          )
          .then(
            (result) => {
              Modal.success({
                title: "Message Sent...",
                content: (
                  <div className="modal-error">
                    <div>
                      <img
                        src={require("../../assets/images/Gifs/emails.gif")}
                        alt="animated"
                      />
                    </div>
                    <p>THANK YOU! </p>
                    <p>We will contact you ASAP</p>
                  </div>
                ),
                onOk() {},
              });
            },
            (error) => {
              Modal.error({
                title: "Sorry!...Email Cannot Send",
                content: (
                  <div className="modal-error">
                    <div>
                      <img
                        src={require("../../assets/images/Gifs/oopss.gif")}
                        alt="animated"
                      />
                    </div>
                    <p>Please try again...</p>
                  </div>
                ),
                onOk() {},
              });
            }
          );
    e.target.reset();
  };

  return (
    <div className="tenfree">
      <div className="tenfree_header"></div>
      <Row className="tenfree_content">
        <Col xs={24} md={12} className="tenfree_content_title">
          {ReactHtmlParser(tenfree.title)}
        </Col>
        <Col xs={24} md={12} className="tenfree_content_form-container">
          <form
            className="tenfree_content_form-container_form"
            onSubmit={sendEmail}
          >
            <input type="hidden" name="tenfree_number" />
            <label>*Subject</label>
            <input
              type="text"
              name="subject"
              value={"10 Free Visual Identity"}
             
            />
            <label>*Name</label>
            <input
              type="text"
              name="name"
              value={mailForm.name}
              onChange={(e) => {
                setMailForm({ ...mailForm, name: e.target.value });
              }}
            />
            <label>*Email</label>
            <input
              type="email"
              name="email"
              value={mailForm.email}
              onChange={(e) => {
                setMailForm({ ...mailForm, email: e.target.value });
              }}
            />
            <label>*Phone</label>
            <input
              type="text"
              name="phone"
              value={mailForm.phone}
              onChange={(e) => {
                setMailForm({ ...mailForm, phone: e.target.value });
              }}
            />
            <label>*Message</label>
            <textarea
              name="message"
              rows="3"
              value={mailForm.message}
              onChange={(e) => {
                setMailForm({ ...mailForm, message: e.target.value });
              }}
            />
            <button type="submit">Send</button>
          </form>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => ({
  service: state.contactCallReducer.service,
});
export default connect(mapStateToProps)(Contact);
