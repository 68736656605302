import React,{useState} from "react";
import {Tabs} from 'antd'
import { about } from "../../data/about.js";
import ReactHtmlParser from "react-html-parser";
import TeamTwo from "../../components/home_team";
const { TabPane } = Tabs;
const About = ({about}) => {
const [showStory, setShowStory] = useState(false)
  return (
    <div className="about">
      <div className="about_tab_container">
      </div>
      <div className="about_content">
        <div className="about_content_subtitle">{about.subtitle}</div>
        <div className="about_content_title">
          <h3> {ReactHtmlParser(about.title)}</h3>
        </div>
        <div className="about_content_desc">
          <p>{ReactHtmlParser(about.desc)}</p>
        </div>
        <div className="about_content_tasks">
          <ul>
          {
            about.tasks.map((task,i)=><li key={i} >{ReactHtmlParser(task)}</li>)
          }
          </ul>
        </div>
      </div>
      <div className="about_content">
        <div className="about_content_subtitle">{about.story.subtitle}</div>
        <div className="about_content_title">
          {/* <h3> {ReactHtmlParser(about.story.desc)}</h3> */}
        </div>
        <div className="about_content_desc">
        <p>{ReactHtmlParser(about.story.desc.part1)} <span onClick={()=>setShowStory(!showStory)} className="more_link">{about.story.desc.more}</span> </p>
          <p style={showStory?{display:"block"}:{display:"none"}}>{ReactHtmlParser(about.story.desc.part2)}</p>
        </div>
      </div>
      <div className="about_content">
        <div className="about_content_subtitle">{about.values.subtitle}</div>
        <div className="about_content_title">
          <h3> {ReactHtmlParser(about.values.title)}</h3>
        </div>
      </div>
      <div className="about_content">
        <div className="about_content_subtitle">{about.mission.subtitle}</div>
        <div className="about_content_title">
          <h3> {ReactHtmlParser(about.mission.title)}</h3>
        </div>
      </div>
      <div className="about_content">
        <div className="about_content_subtitle">{about.vision.subtitle}</div>
        <div className="about_content_title">
          <h3> {ReactHtmlParser(about.vision.title)}</h3>
        </div>
      </div>
      <div className="about_content">
        <div className="about_content_subtitle">{about.ceo.subtitle}</div>
        <div className="about_content_ceo">
          <img  src={require(`../../assets/images/team/kais.png`)}alt=""/>
          <p>{ReactHtmlParser(about.ceo.desc)}</p>
        </div>
      </div>
      <TeamTwo data={about.team} />
    </div>
  );
};

export default About;
