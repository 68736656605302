import React from "react";
import { Col, Row} from "antd";
import { service_call } from "../../data/service_call";
import { build_me } from "../../data/build-me.js";
import ServiceCall from "../../components/service_call";
import ServicesDetails from "../../components/services_details"
import ReactHtmlParser from "react-html-parser";
import CtaContact from "../../components/home_contact";
const Buildme = ({build}) => {
    return (
        <div className="build-me">
        <div className="build-me_header"></div>
        <div className="build-me_content">
          <div className="build-me_content_title">
            {ReactHtmlParser(build.about.title)}
          </div>
          <div className="build-me_content_desc">
            <p>{ReactHtmlParser(build.about.desc)}</p>
          </div>
      
          <ServicesDetails serviceData={build}  />
        </div>
        {/* <ServiceCall serviceName={service_call.call.buildme} /> */}
        <CtaContact data={build.contact}/>
      </div>
    )
}

export default Buildme
