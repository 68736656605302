export const animate_me = {
  about: {
    title:
      " Vous souhaitez produire des <span> vidéos professionnelles </ span>? <br/> Vous avez besoin d'idées créatives de vidéos pour <span> promouvoir </ span> vos services, gagner en <span> visibilité</span>, et attirer plus de <span> clientèle </span>?",
    desc:
      "AnimateMe est exactement ce que vous cherchez. C'est plus qu'un espace de création de vidéos. C'est l'endroit idéal pour donner libre cours à votre imagination et fignoler un message de marketing percutant et attractif. Nous mettons à votre disposition notre expertise en consulting pour vous aider à mettre en valeur votre Unique Selling Propostion et attirer plus de clientèle.",
  },
  contact: {
    subtitle: "Vous avez une idée?",
    title: " <span>nous sommes là pour la réaliser.</span>",
    desc: " Demandez un devis gratuitement !",
    call: "Tendez la main maintenant!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Devis en ligne!",
  },
  services: {
    title: "AnimateMe propose des services <span> personnalisés </span>",
    // services: [
    //   '  <i class="far fa-video"></i> <span>Création et montage vidéo</span>',
    //   '  <i class="far fa-user-edit"></i> <span>Création et animation du caractère</span>',
    //   '  <i class="far fa-film"></i> <span>Animation vidéo</span>',
    //   '  <i class="far fa-money-bill-wave-alt"></i> <span>Lanimation du logo</span>',
    //   '  <i class="far fa-eye"></i> <span>Effets visuels</span>',
    // ],
    services: [
      {
        title: '  <i class="fal fa-video"></i> <span>Vidéographie</span>',
        desc: ["Tournage vidéo", "Montage vidéo", "VFX: Effets visuels"],
      },
      {
        title:
          '  <i class="fal fa-camera-retro"></i> <span>Photographie</span>',
        desc: [
          "Photoshoot",
          "Photoshoot du produit",
          "Paysages",
          "Post-production",
        ],
      },
      {
        title: '  <i class="fal fa-film"></i> <span>Animations 2D</span>',
        desc: [
          "Animation vidéo informative ",
          "Animation vidéo éducative",
          "Animation vidéo promotionnelle",
          "Animation de logo",
          "Vidéos WhiteBoard",
          "Création et animation du caractère",
        ],
      },
      {
        title:
          '  <i class="fal fa-podcast"></i> <span>Production audio </span>',
        desc: ["Jingles", "Effets sonores", "Enregistrement vocal"],
      },
    ],
    servicesBill: [
      "  Création et montage vidéo ",
      "Création et animation du caractère",
      "Animation vidéo",
      "Animation de logo",
      "  Effets visuels",
    ],
  },
};
