export const build_me = {
  about: {
    title:
      " Your products or services go unnoticed in your <span>current website</span>?",
    desc:
      "BuildMe is exactly what you are looking for. It is more than a place for web development. It is a space for you to craft powerful visual attractive marketing messages. We provide you with our business consulting expertise to help you decide on what message to use and how to use it to highlight your Uniqueness Selling Proposition and win clients.",
  },
 
  contact: {
    subtitle: "Ready to start branding?",
    title: " <span>We'll get all your brand needs </span>taken care of.",
    desc: "",
    call: "Reach out Now!",
    phone: "+216 51 500 097",
    email: "contact@brandwiseragency.com",
    btn: "Get a Quote!",
  },    
 
  services: {
    title: "BuildMe offers <span> custom</span> services",
    services: [
      {
        title:
          '  <i class="fal fa-desktop-alt"></i> <span>Websites</span>',
        desc: [
          "Basic showcase ",
          "Custom showcase",
          "Landing Page",
          "E-commerce",
        ],
      },
      {
        title: '  <i class="fal fa-chart-line"></i> <span>SEO</span>',
        desc: [
          "Links building",
          "Website optimization",
          "Sitemap optimization",
          "Search Engine Tools Setup",
          "Traffic & Ranking",
        ],
      },
      {
        title:
          '  <i class="fal fa-mobile"></i> <span>Applications</span>',
        desc: [
          "Web App",
          "Mobile App",
          "Desktop App"
        ],
      },
      {
        title:
          '  <i class="fal fa-envelope-open-text"></i> <span>E-mail </span>',
        desc: ["Email automation", "Email setup","Email signature"],
      },
      {
        title:
          '  <i class="fal fa-server"></i> <span>DevOps</span>',
        desc: [
          "Server hosting",
          "Domain Name",
          "Deployment"
        ],
      },
      {
        title: ' <i class="fal fa-tools"></i> <span>Support & maintenance</span>',
        desc: ["HelpDesk Support", "Server Management", "Dedicated Tech Team"],
      },
    ],
  },
};
