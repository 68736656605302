import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Switch } from "antd";

const Head = ({ setFrensh, isFrensh,headData }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.scrollY < 40 ? setScroll(false) : setScroll(true)
    );
  }, []);
  return (
    <div
      className="header-area"
      style={
        scroll
          ? {
              boxShadow: " 0 8px 20px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#202020",
              top: "0",
            }
          : { boxShadow: "none",top: "40px", backgroundColor: "rgba(0,0,0,0.1)" }
      }
    >
      <div className="header default-menu-style ">
      <div id="logolink">
          <NavLink to="/">
            <img
              id="firstb"
              src={require("../../assets/images/logo/logo-dark.png")}
              className="firstb"
              alt="Brand Logo"
            />

            {/* <img
              id="randme"
              src={require("../../assets/images/logo/randwiserb.png")}
              className="randme"
              alt="Brand Logo"
            /> */}
          </NavLink>
        </div>
        <nav className="navigation-menu ">
          <ul>
            {/* <li>
              <NavLink exact to="/" activeClassName="current_link">
                <span>Home</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/about" activeClassName="current_link">
                <span>{headData.link}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="brandwiser_link"
                to="/brandwiser"
                activeClassName="current_link"
              >
                <span>B.R.A.N.D.W.I.S.E.R™</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/designme" activeClassName="current_link">
                <span>DesignMe</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/animateme" activeClassName="current_link">
                <span>AnimateMe</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/marketme" activeClassName="current_link">
                <span>MarketMe</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/buildme" activeClassName="current_link">
                <span>BuildMe</span>
              </NavLink>
            </li>
            <li>
              <a href="https://blog.brandwiseragency.com" target="_blank" rel="noreferrer noopener">
                <span>Blog</span>
              </a>
            </li>
          </ul>
          {/* </nav> */}
          {/* <nav className=""> */}
        </nav>
       
        <div>
          {/* <NavLink to="/assessment-start" className="assessment-link">
            Assessment
          </NavLink> */}
          <NavLink to="/service-request" className="assessment-link">
          {headData.order}
          </NavLink>
          <NavLink to={`/contact`} className="contact-link">
            Contact
          </NavLink>
          <Switch
            checkedChildren={
              <img
                src={require("../../assets/images/logo/france.svg")}
                alt="Brand Logo"
                style={{ width: "15px !important", height: "15px" }}
              />
            }
            unCheckedChildren={
              <img
                src={require("../../assets/images/logo/united-kingdom.svg")}
                alt="Brand Logo"
                style={{ width: "15px", height: "15px" }}
              />
            }
            onChange={() => setFrensh(!isFrensh)}
          />
        </div>
      </div>
    </div>
  );
};

export default Head;
