import React from "react";
import Header from "./header";
import { Layout } from "antd";
import Footer from "./footer";
const LayoutPage = ({ children,headData }) => {
  return (
    <Layout className="layout">
      <Header headData={headData}/>
      <div 
      // style={{ paddingTop: "100px" }} 
      // className="site-layout-content"
      >
        {children}
      </div>
      <Footer />
    </Layout>
  );
};

export default LayoutPage;
