import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Switch, Menu, Dropdown } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const menu = (
  <Menu className="mobile">
    <Menu.Item>
      <NavLink exact to="/" activeClassName="current_mobile">Home</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/about" activeClassName="current_mobile">about</NavLink>
    </Menu.Item>
    <Menu.Item className="mobile_brandwiser">
      <NavLink to="/brandwiser" activeClassName="current_mobile">BRANDWISER™</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/designme" activeClassName="current_mobile">DesignMe</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/animateme" activeClassName="current_mobile">AnimateMe</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/marketme" activeClassName="current_mobile">MarketMe</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/buildme" activeClassName="current_mobile">BuildMe</NavLink>
    </Menu.Item>
    <Menu.Item className="mobile_assessment">
      <NavLink to="/assessment-start" activeClassName="current_mobile">Assessment</NavLink>
    </Menu.Item>
    <Menu.Item className="mobile_contact">
      <NavLink to="/service-request" activeClassName="current_mobile">Order</NavLink>
    </Menu.Item>
    <Menu.Item className="mobile_contact">
      <NavLink to="/contact" activeClassName="current_mobile">Contact</NavLink>
    </Menu.Item>
  </Menu>
);

const Header_mobile = ({ setFrensh, isFrensh, headData }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.scrollY === 0 ? setScroll(false) : setScroll(true)
    );
  }, []);
  return (
    <div
      className="header-area"
      style={
        scroll
          ? {
              boxShadow: " 0 8px 20px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#2b2b2b",
            }
          : { boxShadow: "none", backgroundColor: "transparent" }
      }
    >
      <div className="header default-menu-style ">
        <div id="logolink">
          <NavLink to="/">
            <img
              id="firstb"
              src={require("../../assets/images/logo/logo-dark.png")}
              className="firstb"
              alt="Brand Logo"
            />
          </NavLink>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MenuOutlined />
            </a>
          </Dropdown>
          <img
            src={
              isFrensh
                ? require("../../assets/images/logo/united-kingdom.svg")
                : require("../../assets/images/logo/france.svg")
            }
            alt="Brand Logo"
            style={{
              width: "15px !important",
              height: "15px",
              cursor: "pointer",
              margin: "0px 5px",
            }}
            onClick={() => setFrensh(!isFrensh)}
          />
        </div>
      </div>
    </div>
  );
};

export default Header_mobile;
