export const assessment = {
  start: {
    title: "Bienvenue à notre outil d'évaluation",
    subtitle: "Ajoutez votre email pour commencer",
    desc: "<span>**</span> Abonnez-vous pour obtenir nos nouvelles offres",
    button: "Démarrer",
  },
  finish: {
    title1: "Vous êtes ",
    title2: " Prêt(e)",
  },
  brandwiser: {
    text:
      "Prêt(e) à démarrer votre entreprise? <br/>Réservez une séance découverte gratuite pour discuter de vos résultats",
    button: "Réserver",
  },
  assessment: {
    title: "Assessment tools",
    subtitle: "Rate yourself from 0 to 10 on the following sentences",
  },
};
