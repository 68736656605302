export const about = {
  subtitle: "We run all kinds of branding that vow your success",
  title:
    "The Future of <span>  Branding </span> is Here and the <span>Possibilities</span> are Endless! ",
  desc:
    // "We use our <span> B.R.A.N.D.W.I.S.E.R™</span> Method to help businesses craft a creative BUSINESS MODEL that produces UNIQUENESS PRODUCTS & SERVICES, position themselves strategically, BRAND THEMSELVES, access new promising local and INTERNATIONAL MARKETS, INCREASE THEIR SALES, and SUSTAIN THEIR GROWTH even in TIMES OF CRISIS (or tough competition).",
    "Through our <span> B.R.A.N.D.W.I.S.E.R™</span> Method we help you: ",
  link: "learn more about BRANDWISER →",
  tasks: [
    "Assess your current <span>brand strengths and weaknesses</span>",
    "Craft a <span>creative business model</span> ",
    "Sharpen their <span>unique selling proposition</span>",
    "Chose the<span> appropriate niche</span>",
    "Define exactly your <span>client avatar</span>",
    "Produce <span>videos</span>, create <span>animated cartoons </span> and design<span> visuals </span>",
    "Convey a powerful<span> marketing message</span>",
    "Access promising<span> international markets</span>",
    "Sustain their <span>growth  </span>even in<span> times of uncertainty</span>",
  ],
  vision: {
    subtitle: "Our vision",
    title:
      "is to be <br/>a <span>one-stop-shop</span> <br/>for all your branding needs",
  },
  story: {
    subtitle: "Our Story",
    desc: {
      more:"view more...",
      part1:
        "The Brandwiser Agency was born out of the gap in the branding industry. At the start of Excellentia, we tried to find an agency who would help us achieve real results, one that would take us step by step to the finish line. All we found were glorified results and vanity metrics that did so little to the actual growth of the company.",
      part2:
        "That’s when we decided to come up with our own approach to branding. We designed a Coachpreneur program based on our own journey and business model that helped coaches start their businesses. The program is called BRANDWISERTM. It is a 10 weeks' time hands-on coaching program based on many powerful strategies, tools and techniques. <br/><br/>Proven to be successful with coaches, <span>BRANDWISER</span>  is now more than a program… It became an independent business that is meant to serve all those who would like to express their uniqueness, build profitable businesses, access new markets, attract more leads, win clients, and achieve their dreams.",
    },
  },
  ceo: {
    subtitle: "Message from founder and CEO Kais Gannouni:",
    desc:
      " “ On behalf of our great Team, I would like to welcome you to the Brandwiser Agency. We are always at your service, working with passion and dedication to offer you a full branding experience that is customized to your exact needs. ”",
  },
  mission: {
    subtitle: "Our mission",
    title: "To create brands that <span> last</span>",
  },
  values: {
    subtitle: "Our Values",
    title:
      "creativity, <br/> Passion, <br/> authenticity, <br/> value-added, <br/> & Especially No mass production",
  },
  team: {
    subtitle: "Our team",
    title:
      "We pride ourselves on having <br>a <span>highly-skilled</span> team",
  },
};
