import { home } from "./home";
import { about } from "./about";
import { animate_me } from "./animate-me";
import { brandwiser } from "./brandwiser";
import { build_me } from "./build-me";
import { contact } from "./contact";
import { design_me } from "./design-me";
import { market_me } from "./market-me";
import { service_call } from "./service_call";
import { service_bill } from "./services";
import { team } from "./team";
import {assessment} from './assessment'
import { header } from "./header";
import { tenfree } from "./tenfree";

const dataFr = {
  home,
  about,
  animate_me,
  brandwiser,
  build_me,
  contact,
  design_me,
  market_me,
  service_call,
  service_bill,
  team,
  header,
  assessment,
  tenfree
};
export default dataFr;
