import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'
import './assets/scss/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { createBrowserHistory } from 'history';
import store from "./store";



 // Start google analytics
import ReactGA from 'react-ga';
// import auth from './auth.ts'; // Sample authentication provider

 // Browser history
 const history = createBrowserHistory();

const trackingId = "UA-178685531-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

 // track Pageview with Browser history
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })
 // End google analytics

ReactDOM.render(
  <Provider store={store}>
  {/* <React.StrictMode> */}
    <App />
  {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
